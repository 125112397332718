<section class="is-fullscreen" id="faq-section-res">

  <h1 class="section-title-type-a  page-gutter-padding fade-in" id="faq-title-res">Frequently Asked Questions</h1>
    <h3 id="faq-title-res-desc" class="page-gutter-padding fade-in">Questions that we are often asked by practitioners during training sessions - and our answers!</h3>
  <div id="res-faq-accordion">
    <mat-accordion multi="true">

            <mat-expansion-panel class="res-faq-accordion-panel page-gutter-padding">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>Goal Attainment Scaling (G.A.S) looks really useful but I’m worried it would take ages and possibly be overwhelming to parents?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">We would recommend that you identify two priority goals for parents with which to use G.A.S.  These should be changes or goals, that if achieved would make a real difference to the well-being of the children concerned, and would help professionals see that positive change was happening.</p>
              <p class = "faq-text">Whilst completing G.A.S with parents will take longer than setting other types of goals, we believe it brings benefits to the collaborative relationship with parents, and clarity about what needs to be achieved, and what acceptable progress looks like.  Parents often feedback that they were not entirely sure what social workers were asking of them, or that they felt that goalposts were moved if they made small changes, or that they were set up to fail with unrealistic expectations.  Using G.A.S can help to avoid these issues, as well as providing clear evidence of actual change, or lack of positive change.</p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>Understanding a parent’s capacity for change is all well and good, but I need to see that parents can make actual changes to inform my decisions for the children. Can’t I just use the goal setting process?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
                <p class = "faq-text">Sustained behavioural change can take many months, especially if issues are long-standing or if a parent is being asked to become abstinent from substances or reduce levels of conflict in their relationships.  Plans and decisions may need to be taken before there is time to reach an end goal or see if new behaviours can be sustained.  In this instance, social workers need to be able to form a view about whether positive change is likely and use that to inform the direction of their work.  When dealing with uncertainty, the more information you have the better, so understanding the barriers and facilitators for change that are present in a parent and their environment will contribute to a social worker’s understanding of the likelihood of actual change.</p>
                <p class = "faq-text">Understanding a parent’s barriers and facilitators will also support decisions about therapeutic or training interventions that will be appropriate and accessible for parents and encourage success rather than setting parents up to fail. For example, imagine that a parent needs to change the way they implement boundaries for their children.  After assessing barriers and facilitators, a social worker might understand that a parent’s knowledge and skills regarding appropriate boundary setting are good and they facilitate change.  But they have also discovered that the norm in the wider family is to use physical chastisement, and that members of the wider family are often present in the family home sharing their opinions. The parents in the family feel they need to accept the advice from their extended family - these would all be barriers to change. In this instance, asking the parent to attend a parenting course to learn about boundary setting is unlikely to make a difference to their ability to change their behaviour; working with the parent around assertiveness, or considering a family group meeting to bring the wider family into the plan might be more likely to make a difference.</p>
                <p class = "faq-text">Understanding barriers and facilitators can therefore make a real difference to the success of support work and interventions, and to a parents’ ability to demonstrate real change.</p>
            </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>There are so many complex issues with a family I work with and related changes that I’d like the parent to make. I can’t possibly assess their capacity to change all of them - how do I decide which behaviours to focus on?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">C-Change is designed as a complementary assessment which focuses on parental capacity for change.  It does not replace a needs assessment.</p>
              <p class = "faq-text">Children’s services work with parents to make changes for the benefit of their child, to better meet their needs and / or to reduce the risk of harm.  We suggest that the changes to prioritise are those that are in the control of the parent and that will have a significant, positive impact on the child’s experience if they are to occur.  The changes should also be meaningful to professionals - ask yourself, “if the parent achieves this particular change, will I be significantly less worried?”</p>
              <p class = "faq-text">It is important to be open and honest with parents about change being an ongoing process with several steps along the way, and that there are likely to be a number of changes that are needed during involvement with Children’s Services.  Achieving ‘quick wins’ can be helpful to improve feelings of self-efficacy but ultimately, if you’re not addressing the significant issues then parents may feel that the goal posts have been moved and they have been set up to fail.</p>
            </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>When there are several parents or carers involved with a child, whose capacity for change should I be assessing?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">Think about what the child needs, and who needs to be changing to meet that need.  For example, if a toddler needs their parents to talk to them more to help their speech develop then the focus is likely to be on the main carer for that child - the parent who spends the most time with the child and so has more opportunity to make a difference.  If that main carer is the mother, and her capacity for change seems to be good but the father has less capacity to change, that might be OK if he has less time with the child and so is less  influential in terms of speech development.  But, it might not be OK if the Dad is critical of the Mum’s efforts to change the way she talks to their child, undermines her attempts and she prioritises maintaining a calm relationship with him over attending to their child’s speech.</p>
              <p class = "faq-text">Two key questions to ask are: </p>
              <ul class = "faq-text">
                  <li>“Which change in which parent or carer is going to make the most difference for the child?”</li>
                  <li>“Are the other parents / carers likely to support or allow or hinder the change?”</li>
              </ul>
              </div>
            </mat-expansion-panel>

        </mat-accordion>
  </div>

  <div class="resources-menu-hero-divider-2 custom-shape-divider custom-shape-divider-bottom">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs>
              <linearGradient id="gradient" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#0CC6DD" />
                <stop offset="100%" stop-color="#0CC6DD" />
              </linearGradient>
          </defs>
          <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>
</section>
