import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-globalHeader',
  templateUrl: './globalHeader.component.html'
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {

  userIsAuthenticated = false;
  private authListenerSubs: Subscription;

  constructor(private authService: AuthService, private viewportScroller: ViewportScroller) {}

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

  scrollTo(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor);
  }
}
