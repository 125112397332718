import { AudienceContent } from './audienceContent.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class AudienceContentService {
  private audienceContents: AudienceContent[] = [];
  private audienceContentsUpdated = new Subject<AudienceContent[]>();

constructor(private http: HttpClient) {}

  getAudienceContents() {
//    this.http.get<{message: string, audienceContents: any}>('http://localhost:3000/api/audienceContent')
//    this.http.get<{message: string, audienceContents: any}>('https://cchange.herokuapp.com/api/audienceContent')
  this.http.get<{message: string, audienceContents: any}>('https://cchange.herokuapp.com/api/audienceContent')
    .pipe(map((audienceContentData) => {
      return audienceContentData.audienceContents.map((audienceContent: any) => {
        return {
          title: audienceContent.title,
          content: audienceContent.content,
          id: audienceContent._id
        };
      });
    })) //the result of the pipe is the transformedfaqData
    .subscribe ((transformedAudienceContentData) => {
      this.audienceContents = transformedAudienceContentData;
      this.audienceContentsUpdated.next([...this.audienceContents]);
    });
  //  return [...this.faqs];
  }

  getAudienceContentUpdateListener() {
    return this.audienceContentsUpdated.asObservable();
  }

  addAudienceContent(title: string, content: string) {
    const audienceContent: AudienceContent = {id: 'null', title: title, content: content};
//    this.http.post<{message: string, audienceContents: AudienceContent[]}>('http://localhost:3000/api/insertAudienceContentList', audienceContent)
//    this.http.post<{message: string, audienceContents: AudienceContent[]}>('https://cchange.herokuapp.com/api/audienceContent', audienceContent)
   this.http.post<{message: string, audienceContents: AudienceContent[]}>('https://cchange.herokuapp.com/api/audienceContent', audienceContent)
    .subscribe ((responseData) => {
        console.log(responseData.message);
        this.audienceContents.push(audienceContent);
        this.audienceContentsUpdated.next([...this.audienceContents]);
      });
  }
}
