<section class="is-width-large is-center is-container-column is-100 is-inset-bottom-80 is-inset-top-40 q-submission-section">
    <div class="is-row is-center">
      <div class="is-col is-100">
        <h1 class="is-inset-y-60">Here's what our audience wants to know today.</h1>

      <mat-accordion multi="true" *ngIf="audienceContents.length > 0">
              <mat-expansion-panel *ngFor="let audienceContent of audienceContents">
                <mat-expansion-panel-header>
                  {{ audienceContent.title }}
                </mat-expansion-panel-header>
                <p>{{ audienceContent.content }}</p>
              </mat-expansion-panel>
            </mat-accordion>
            <p class="info-text mat-body-1" *ngIf="audienceContents.length <= 0">No content yet!</p>

  </div>
  </div>
  </section>
