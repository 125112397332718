import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../../auth/auth.service";

@Component({
  templateUrl: './audienceContentReview.component.html'
})
export class AudienceContentReviewComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  isAdmin = false;
  private authListenerSubs: Subscription;
  private adminStatusSubs: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.isAdmin = this.authService.getIsAdmin(); // Get initial admin status

  
    // Subscription for authentication changes
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });

    // Subscription for admin status changes
    this.adminStatusSubs = this.authService
      .getAdminStatusListener()
      .subscribe(isAdmin => {
        this.isAdmin = isAdmin;
        console.log('Updated Admin Status:', this.isAdmin); // Debug: Check updated status
      });
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.adminStatusSubs.unsubscribe(); // Make sure to unsubscribe from the admin listener
  }
}
