
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component'; // Ensure this component exists

import { AuthService } from '../auth.service';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  hide = true;

  constructor(public authService: AuthService, private dialog: MatDialog) {}

  onLogin(form: NgForm) {
    if (form.invalid) {
      this.openDialog("Please check your input and try again.");
      return;
    }
    this.authService.login(form.value.email, form.value.password).subscribe({
      next: (response) => {
        console.log('Login successful:', response);
        // The navigation is handled in AuthService upon successful login
      },
      error: (error) => {
        console.error('Login error:', error);
        this.openDialog("Failed to login. Check your credentials and try again.");
      }
    });
  }

  private openDialog(message: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { message: message }
    });
  }
}

/*
import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";

import { AuthService } from "../auth.service";

@Component({
  templateUrl: "./login.component.html",
})
export class LoginComponent {

  hide = true;


  constructor(public authService: AuthService) {

  }
  onLogin(form: NgForm) {
    if (form.invalid) {
      console.log("Oh Poo");
      return;
    }
    this.authService.login(form.value.email, form.value.password);
  }

}

*/
