import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-homepage-video-1',
  templateUrl: './homepage-video-1.component.html'
})
export class HomepageVideo1Component implements OnInit, AfterViewInit {

  @ViewChild('fullscreenMidVideo') fullscreenMidVideo!: ElementRef<HTMLVideoElement>;
  @ViewChild('playButton') playButton!: ElementRef<HTMLDivElement>;

  constructor() { }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  ngAfterViewInit(): void {
    this.setupPlayButton();
  }

  private setupPlayButton(): void {
    this.playButton.nativeElement.addEventListener('click', () => {
      const videoElement = this.fullscreenMidVideo.nativeElement;
      videoElement.play()
        .then(() => {
          // Playback started successfully
          console.log('Playback started successfully');
        })
        .catch(error => {
          // Error trying to play the video
          console.error('Error trying to play the video:', error);
        });
      this.playButton.nativeElement.style.display = 'none'; // Hide play button after play
    });
  }
}
