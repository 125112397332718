<section class="is-fullscreen" id="hero-section">
  <div id="fullscreenIntroContent">
    <video playsinline autoplay muted loop preload="auto" id="heroVideo1" class="heroVideo">
        <source src="../assets/video/newherocomp.mp4" type="video/mp4">
    </video>
    <div class="strapLineDiv is-row">
      <h1 id="strapLine" class="on-start-fade-in-a">Welcome to <br>C-Change.</h1>
    </div>
  </div>
  <div class="custom-shape-divider custom-shape-divider-bottom overview-shape-divider">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs>
              <linearGradient id="gradient" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#3A5078" />
                <stop offset="100%" stop-color="#3A5078" />
              </linearGradient>
          </defs>
          <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>
</section>

<section id="overview-section" class="is-fullscreen">
  <div id="overview" class="section-overview on-start-fade-in-b">
    <div class="overview-text page-gutter-padding">
      <h1 id="overview-title" class="section-title-type-b">The C-Change Approach:<br>Assessing Parental Capacity to Change</h1>
       <p class="overview-lead"><strong>C-Change is a flexible approach to assessing parents’ capacities to change where the children are in need or at risk of harm.
         C-Change aims to fill a gap in practice guidance and help practitioners think through questions of whether parent(s) can change things sufficiently to ensure that their child is well cared for into the future.
         C-Change is designed to be used alongside needs assessments of families when there are questions about the likelihood of parents having the capacity to make positive changes to their behaviour, or lifestyle,
         for the benefit of their children.  The C-Change approach to assessment focuses specifically on parental capacity to change, with the aim of informing future planning and decision-making for the child and is
         intended to  be used by social workers and other professionals working with children in need or at risk of harm.</strong></p>
        <p class="overview-follow">C-Change was developed in England alongside social workers and it’s utility and relevance to practice was evaluated in collaboration with three participating Local Authorities.
          Practitioners reported an increase in knowledge and confidence about assessing a parent’s capacity for change, and an improvement in their ability to make decisions about plans for a child within the child’s timeframe.</p>
        <p class="overview-follow">The C-Change materials and handbook reflect the English context and procedures of Children and Families Social Work.  A handbook that reflects the Scottish legal and procedural context is also available.
          As an assessment approach, the principles and framework of C-Change are applicable and relevant to work with families across the world and have been used across the UK, in Europe, Australia and North America.</p>
    </div>
 </div>
 </section>
