import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/homepage/home/home.component';
import { HiwComponent } from './pages/homepage/hiw/hiw.component';
import { HomeFaqComponent } from './pages/homepage/homefaq/homefaq.component';
import { TeamComponent } from './pages/homepage/team/team.component';
import { ResourcesMenuComponent } from './pages/resourcesmenu/resourcesmenu.component';
import { AudienceContentReviewComponent } from './pages/audienceContentReview/audienceContentReview.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'resourcesmenu', component: ResourcesMenuComponent },
  { path: 'audienceContentReview', component: AudienceContentReviewComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
	{ path: 'team', component: TeamComponent },
	{ path: 'hiw', component: HiwComponent },
	{ path: 'homefaq', component: HomeFaqComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64], // Optional: Adjust based on your header height
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
