import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html'
})
export class TeamComponent implements OnInit {

  readMore1 = false;
  readMore2 = false;
  readMore3 = false;
  readMore4 = false;

  ngOnInit(): void { }

  readMore(data: number) {
    if (data === 1) {
      this.readMore1 = !this.readMore1;
    } else if (data === 2) {
      this.readMore2 = !this.readMore2;
    } else if (data === 3) {
      this.readMore3 = !this.readMore3;
    } else if (data === 4) {
      this.readMore4 = !this.readMore4;
    }
  }
}
