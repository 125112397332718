<section id="share-experience-section" class="is-fullscreen">

    <div class="share-experience-text page-gutter-padding">
        <h1 id="share-experience-title">Share your experiences</h1>
    <div>
      <p>We would really like to encourage you to give us feedback about the C-Change approach or about your experiences of working with parents who need to change, and of applying C-Change.
        Please use this space to anonymously share your experiences, comments, criticisms and questions. We will use your feedback to further improve the website and develop relevant FAQs.</p>
      <p>If you would like an individual reply to a question or comment, or to engage in a 1:1 dialogue about parental capacity to change then please email
          Katie Riches at katie.riches@:bristol.ac.uk. We’d love to hear from you!</p>
    </div>

    <div class="form-box" id="share-experience-content-box">
      <div id="" class="share-experience-content-box-item">
      <mat-card>
        <form (submit)="onAddAudienceQuestion(audienceQuestionForm)" #audienceQuestionForm="ngForm">
          <mat-form-field>
            <input
              matInput
              type="text"
              name="title"
              ngModel
              required
              minlength="3"
              placeholder="Add a query title"
              #title="ngModel">
            <mat-error *ngIf="title.invalid">Please enter a query title.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              rows="4"
              name="content"
              ngModel
              required
              placeholder="Add query content"
              #content="ngModel"></textarea>
            <mat-error *ngIf="content.invalid">Please enter query content.</mat-error>
          </mat-form-field>
          <button
            mat-raised-button
            color="accent"
            type="submit">Post Question</button>
        </form>
      </mat-card>
    </div>
    </div>
  </div>


  </section>
