 import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-hiw",
  templateUrl: './hiw.component.html'
})
export class HiwComponent implements OnInit {

  showTopBox = false;
  showMiddleBox = false;
  showBottomBox = false;
  slideInTopAnim = false;
  slideInMiddleAnim = false;
  slideInBottomAnim = false;
  slideOutTopAnim = false;
  slideOutMiddleAnim = false;
  slideOutBottomAnim = false;

  showRightCol = true;
  showLeftCol = true;
  boxDisplayNone = false;
  boxDisplay = false;
  boxDisplay100 = false;
  showButtons = true;
  buttonFadeIn = true;
  buttonFadeOut = false;

  slideInFullPane = false;
  slideOutFullPane = true;

  hiwTitle = " hiw Title";
  hiwContent = "hiw Content paragraph.";

  mobileMedia: any = window.matchMedia("(max-width:900px)");

  constructor() {

    if(this.mobileMedia.matches) {
      this.showLeftCol = true;
      this.showRightCol = true;
  }

    else {
      this.showLeftCol = true;
      this.showRightCol = true;
    }
}

  ngOnInit(): void {

  }

  slideBoxChooser(data) {

    if(this.mobileMedia.matches) {
      this.hiwSlideBoxButtonMobile(data);
    }

    else {
      this.hiwSlideBoxButtonDesktop(data)
    }
  }



  hiwSlideBoxButtonMobile(data) {

    this.buttonFadeOut = !this.buttonFadeOut;
    this.buttonFadeIn = !this.buttonFadeIn;
    this.slideInFullPane = !this.slideInFullPane;
    this.slideOutFullPane = !this.slideOutFullPane;
    this.hiwSlideBoxContentMobile(data);
  }


  hiwSlideBoxContentMobile(data) {

    if(data=='topButton'){
        this.showTopBox = !this.showTopBox;
      }

    if(data=='middleButton'){
        this.showMiddleBox = !this.showMiddleBox;
      }


    if(data=='bottomButton'){
        this.showBottomBox = !this.showBottomBox;
      }
  }


  hiwSlideBoxButtonDesktop(data) {

    if(data=='topButton'){
      if(!this.showTopBox) {
        this.hiwSlideOutMiddleBoxButton();
        this.hiwSlideOutBottomBoxButton();
        console.log("hey top");
        this.slideInTopAnim = true;
        this.showTopBox = true;
      }

      else if(this.showTopBox) {
        this.slideInTopAnim = false;
        this.slideOutTopAnim = true;
        setTimeout (() => {
               console.log("bye top");
               this.showTopBox = false;
               this.slideOutTopAnim = false;
            }, 1000);
      }
    }

    if(data=='middleButton'){
      if(!this.showMiddleBox) {
        this.hiwSlideOutTopBoxButton();
        this.hiwSlideOutBottomBoxButton();
        this.slideInMiddleAnim = true;
        this.showMiddleBox = true;
      }

      else if(this.showMiddleBox) {
        this.slideInMiddleAnim = false;
        this.slideOutMiddleAnim = true;
        setTimeout (() => {
               console.log("bye top");
               this.showMiddleBox = false;
               this.slideOutMiddleAnim = false;
            }, 1000);
      }
    }

    if(data=='bottomButton'){
      if(!this.showBottomBox) {
        this.hiwSlideOutTopBoxButton();
        this.hiwSlideOutMiddleBoxButton();
        this.slideInBottomAnim = true;
        this.showBottomBox = true;
      }

      else if(this.showBottomBox) {
        this.slideInBottomAnim = false;
        this.slideOutBottomAnim = true;
        setTimeout (() => {
               console.log("bye top");
               this.showBottomBox = false;
               this.slideOutBottomAnim = false;
            }, 1000);
      }
    }

  }

  hiwSlideInTopBoxButton(data) {

    if(!this.showTopBox) {
      this.slideInTopAnim = true;
      this.showTopBox = true;
    }


    else if(this.showTopBox) {
      this.slideInTopAnim = false;
      this.slideOutTopAnim = true;
      setTimeout (() => {
             this.showTopBox = false;
             this.slideOutTopAnim = false;
          }, 1000);
    }
  }

  hiwSlideOutTopBoxButton() {
    this.slideInTopAnim = false;
    this.showTopBox = false;
  }

  hiwSlideInMiddleBoxButton(data) {

    if(!this.showMiddleBox) {
      console.log("hello middle");
      this.slideInMiddleAnim = true;
      this.showMiddleBox = true;
    }


    else if(this.showMiddleBox) {
      this.slideInMiddleAnim = false;
      this.slideOutMiddleAnim = true;
      setTimeout (() => {
        console.log("bye middle");
             this.showMiddleBox = false;
             this.slideOutMiddleAnim = false;
          }, 1000);
    }
  }

  hiwSlideOutMiddleBoxButton() {
    console.log("gotta go dogg");
    this.slideInMiddleAnim = false;
    this.showMiddleBox = false;
  }

  hiwSlideInBottomBoxButton(data) {

    if(!this.showBottomBox) {
      console.log("hello bottom");
      this.slideInBottomAnim = true;
      this.showBottomBox = true;
    }


    else if(this.showBottomBox) {
      this.slideInBottomAnim = false;
      this.slideOutBottomAnim = true;
      setTimeout (() => {
             console.log("bye bottom");
             this.showBottomBox = false;
             this.slideOutBottomAnim = false;
          }, 1000);
    }
  }

  hiwSlideOutBottomBoxButton() {
    this.slideInBottomAnim = false;
    this.showBottomBox = false;
  }

  closeAllBoxes(data) {

    this.hiwSlideBoxButtonMobile(data);
    this.showTopBox = false;
    this.slideOutTopAnim = false;
    this.showMiddleBox = false;
    this.slideOutMiddleAnim = false;
    this.showBottomBox = false;
    this.slideOutBottomAnim = false;

  }

}
