 import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-testimonial",
  templateUrl: './testimonial.component.html'
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
