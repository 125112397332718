import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthData } from "./auth-data.model";
import { jwtDecode } from 'jwt-decode';


@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticated = false;
  private isAdmin = false;
  private token: string;
  private authStatusListener = new Subject<boolean>();
  private adminStatusListener = new Subject<boolean>();
  private tokenTimer: any;  // Use 'any' or a more specific type if possible, like 'NodeJS.Timeout'


    constructor(private http: HttpClient, private router: Router) {}

    getToken() {
      return this.token;
    }

    getIsAuth() {
      return this.isAuthenticated;
    }

    getAuthStatusListener() {
      return this.authStatusListener.asObservable();
    }

  getIsAdmin() {
    return this.isAdmin;
  }

  getAdminStatusListener() {
    return this.adminStatusListener.asObservable();
  }

  createUser(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post("https://c-change-f65713f2d6c4.herokuapp.com/api/user/signup", authData)
      .subscribe(response => {
        console.log(response);
      });
  }

/*
  login(email: string, password: string): Observable<any> {
    const authData: AuthData = { email, password };
    return this.http.post<{ token: string; expiresIn: number }>(
      "https://c-change-f65713f2d6c4.herokuapp.com/api/user/login",
      authData
      .pipe(map(response => {
        const token = response.token;
        if (token) {
          this.token = token;
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const decodedToken = jwt_decode<any>(token); // Decode the token to read the isAdmin flag
          this.isAdmin = decodedToken.isAdmin;
          this.adminStatusListener.next(this.isAdmin);
          this.saveAuthData(token, new Date(decodedToken.exp * 1000));
        }
        return response;
      }));
  }
*/

  login(email: string, password: string): Observable<any> {
    const authData: AuthData = { email: email, password: password };
    return this.http.post<{ token: string; expiresIn: number }>(
      "https://c-change-f65713f2d6c4.herokuapp.com/api/user/login",
      authData
    ).pipe(
      map(response => {
        const token = response.token;

        if (token) {
          this.token = token;
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          const decodedToken = jwtDecode<any>(token); // Decode the token to read the isAdmin flag
          this.isAdmin = decodedToken.isAdmin;
          this.adminStatusListener.next(this.isAdmin);
          this.saveAuthData(token, expirationDate);
          this.router.navigate(["/resourcesmenu"]);
          console.log('Login Admin Status:', this.isAdmin); // Debug: Check initial status

        }

        return response;
      })
    );
  }


  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
      this.isAdmin = authInformation.isAdmin; // Set isAdmin from stored data
      this.adminStatusListener.next(this.isAdmin); // Update listeners
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
  }

  private setAuthTimer(duration: number) {
    console.log("Setting timer: " + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem('isAdmin', JSON.stringify(this.isAdmin)); // Store isAdmin as a string
  }

  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem('isAdmin');
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin')); // Retrieve isAdmin and parse it
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      isAdmin: isAdmin
    }
  }


  // Additional methods...
}


/*
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthData } from "./auth-data.model";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) {}

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }


//"https://cchange.herokuapp.com/api/user/signup"
  createUser(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post("https://c-change-f65713f2d6c4.herokuapp.com/api/user/signup", authData)
      .subscribe(response => {
        console.log(response);
      });
  }

  login(email: string, password: string): Observable<any> {
    const authData: AuthData = { email: email, password: password };
    return this.http.post<{ token: string; expiresIn: number }>(
      "https://c-change-f65713f2d6c4.herokuapp.com/api/user/login",
      authData
    ).pipe(
      map(response => {
        const token = response.token;
        if (token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(token, expirationDate);
          this.router.navigate(["/resourcesmenu"]);
        }
        return response;
      })
    );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
  }

  private setAuthTimer(duration: number) {
    console.log("Setting timer: " + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
  }

  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate)
    }
  }
}
*/
