<!--introduction panel 1-->
<section class="is-container is-inset-x-48">
<div class="is-60" style="margin-top: 100px">
   <h1>Privacy Policy</h1>
   <h2>Data protection</h2>
   <p>We have written this privacy policy (05/03/2021, last updated 05/03/21) to explain to
       you, in accordance with the provisions of the General Data Protection Regulation (EU)
       2016 / 679, what information we collect, how we use data and what decision-making options you
       as a visitor to this website have.</p>
   <h2>Automatic data storage</h2>
   <p>When you visit websites today, certain information is automatically created and
       stored, including on this website.</p>
   <p>If you visit our website, our web server (computer on which this website
       is stored) automatically saves data such as</p>
   <ul>
       <li>the address (URL) of the website you are visiting</li>
       <li>browser and browser version</li>
       <li>the operating system used</li>
       <li>the address (URL) of the previously visited page (referrer URL)</li>
       <li>the host name and IP address of the device from which it is being accessed</li>
       <li>date and time</li>
   </ul>
   <p>in files (web server log files).</p>
   <p>We do not operate a strict policy regarding the management of server log files. Typically, web
       server log files are stored for at least two weeks. We do not archive these records. We do not
       disclose this information, but we cannot rule out that it will be seen in the event of unlawful behavior.</p>
   <p>The legal basis, according to <a
           href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111097873"
           rel="noreferrer" target="_blank">Article 6 (1f) of the GDPR</a> (lawfulness
       of
       processing), is that there is a legitimate interest in enabling the error-free operation of this website by capturing web server log files.</p>
   <h2>Storage of personal data</h2>
   <p>This website does not provide any mechanism for storing Personal data beyond what is captured
       automatically through its operation. We do, however, offer an external link to the Mailchimp
       service for visitors to subscribe to our mailing list.
   </p>
   <p>If you send us personal information by e-mail - outside this website - we cannot
       guarantee secure transmission and protection of your data. We recommend that you
       never send confidential data by e-mail.</p>
   <h2 id="rights-according-to-the-general-data-protection-regulation">Rights according
       to
       the General Data Protection Regulation</h2>
   <p>According to the regulations of the GDPR you
       have
       the following rights:</p>
   <ul>
       <li>Right to rectification (Article 16 GDPR)</li>
       <li>Right to cancellation (&ldquo;Right to be forgotten&rdquo;) (Article 17
           GDPR)
       </li>
       <li>Right to restriction of processing (Article 18 GDPR)</li>
       <li>Right to a notification - Obligation to notify in connection with the
           correction
           or deletion of personal data or limitation of processing (Article 19 GDPR)
       </li>
       <li>Right to data portability (Article 20 GDPR)</li>
       <li>Right of objection (Article 21 GDPR)</li>
       <li>Right not to be subjected to a decision based solely on automated processing
           -
           including profiling - (Article 22 GDPR)</li>
   </ul>
   <p>If you believe that the processing of your data violates data protection law or
       your
       data protection claims have otherwise been violated in any way, you can complain
       to
       the relevant supervisory authority for your country of residence. For the UK, this would be the
       <a href="https://ico.org.uk/make-a-complaint/">Information Commissioners Office</a>.</p>
   <h2>Evaluation of visitor behavior</h2>
   <p>In the following privacy policy we inform you about whether and how we evaluate
       data
       of your visit to this website. The evaluation of the collected data is usually
       anonymous and we cannot infer from your behavior on this website on your
       person.
   </p>
   <p>You can read more about the possibilities of this evaluation of the visit data in
       the
       following privacy statement.</p>
   <h2 id="cookies">Cookies</h2>
   <p>Our website uses HTTP cookies to store user-specific data. A cookie is a short
       data
       packet that is exchanged between the web browser and the web server, but is
       completely meaningless to them and is only used for the web application, e.g. an
       online store can save the contents of a virtual shopping cart.</p>
   <p>There are two types of cookies: first-party cookies are created by our website,
       and
       third-party cookies are created by other websites (e.g. Google Analytics).</p>
   <p>Example cookie data:</p>
   <ul>
       <li>Name: _ga</li>
       <li>Expiration time: 2 years</li>
       <li>Use: Differentiation of website visitors</li>
       <li>Exemplary value: GA1.2.1326744211.152111097873</li>
   </ul>
   <p>There are three categories of cookies: essential cookies to ensure basic
       functionality of the website, functional cookies to ensure website performance
       and
       targeted cookies to enhance the user experience.</p>
   <h3 id="how-do-we-use-cookies">How do we use cookies?</h3>
   <p>Videos embedded in our pages use cookies for the playback control.</p>
   <p>Enabling these cookies is not strictly necessary for the website to work but it
       will
       provide you with a better browsing experience. You can delete or block these
       cookies, but if you do that some features of this site may not work as intended.
   </p>
   <h3 id="how-to-control-cookies">How to control cookies?</h3>
   <p>You can control and/or delete cookies as you wish – for details, see <a
           href="https://aboutcookies.org/" rel="noreferrer" target="_blank">aboutcookies.org</a>.
       You can delete all cookies that are
       already on your computer and you can set most browsers to prevent them from
       being
       placed. If you do this, however, you may have to manually adjust some
       preferences
       every time you visit a site and some services and functionalities may not work.
   </p>
   <h2 id="newsletter-privacy-policy">Mailing List Privacy Policy</h2>
   <p>If you subscribe to our mailing you give
       us the right to contact you by email. We use the data stored in the registration
       for
       the mailing list exclusively for our mailing list and do not pass this on.</p>
   <p>If you unsubscribe from the mailing list - you will find the link in each
       mail from us at
       the bottom - then we delete all data that was saved with the registration for
       the
       mailing list.</p>
   <h2 id="mailchimp-privacy-policy">MailChimp Privacy Policy</h2>
   <p>We send out newsletters with MailChimp and use features of the MailChimp
       service of The Rocket Science Group, LLC, 675 Ponce de Leon Ave. NE, Suite 5000,
       Atlanta, GA 30308 USA to record mailing list subscriptions.</p>
   <h3 id="general-information-about-mailchimp">General information about MailChimp
   </h3>
   <p>Rocket Science Group LLC (MailChimp) maintains online platforms that enable our
       users
       to stay in touch with their subscribers, primarily via email. They allow users,
       to
       upload e-mail addresses, and other subscriber profile information, such as name,
       physical address, and other demographic information to the MailChimp database.
       This
       information is used to send emails and allow the use of certain other MailChimp
       features for these users. In accordance with the published privacy policy,
       MailChimp
       shares some information with third parties to provide and support the services
       that
       MailChimp provides to users. MailChimp also shares some information with third
       party
       advertisers to better understand users&rsquo; needs and interests so that more
       relevant content and targeted advertising can be provided to these users and
       other
       users.</p>
   <h3 id="newsletter-sign-up">Mailing list Sign up</h3>
   <p>When you sign up for our mailing list on our website, the data entered will be
       stored
       by MailChimp.</p>
   <h3 id="deletion-of-your-data">Deletion of your data</h3>
   <p>You can withdraw your consent to receive messages from us via our mailing list at any time within
       the
       received e-mail by clicking on the link at the bottom. If you have unsubscribed
       by
       clicking on the unsubscribe link, your data will be deleted at MailChimp.</p>
   <h3 id="newsletter-evaluation">Mailing list evaluation</h3>
   <p>When you receive a message from us via MailChimp, information such as IP address,
       browser
       type and e-mail program are stored to give us information about the performance
       of
       our message. MailChimp can use the images in the HTML emails called Web
       Beacons
       (see <a href="https://kb.mailchimp.com/reports/about-open-tracking" rel="noreferrer"
           target="_blank">kb.mailchimp.com/reports/about-open-tracking</a> for
       details) to
       see if the email has arrived, whether it was opened and if links were clicked.
       All
       this information is stored on the servers of MailChimp, not on this website.</p>
   <p>MailChimp is an active participant in the EU-US Privacy Shield Framework, which
       regulates the correct and secure transfer of personal data. More information can
       be
       found at <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG"
           rel="noreferrer"
           target="_blank">www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG</a>.
       You
       can read more about the use of cookies at MailChimp at <a
           href="https://mailchimp.com/legal/cookies/" rel="noreferrer"
           target="_blank">mailchimp.com/legal/cookies/</a>, for information on privacy
       at
       MailChimp (Privacy), please refer to <a href="https://mailchimp.com/legal/privacy/"
           rel="noreferrer" target="_blank">mailchimp.com/legal/privacy/</a>.</p>
   <h2 id="google-analytics-privacy-policy">Google Analytics Privacy Policy</h2>
   <p>We use Google Analytics from Google LLC (1600 Amphitheater Parkway Mountain View,
       CA
       94043, USA) to statistically analyze visitor data. Google Analytics uses
       targeted
       cookies.</p>
   <p>Cookies from Google Analytics:</p>
   <ul>
       <li>_ga<ul>
               <li>Expiration time: 2 years</li>
               <li>Use: Differentiation of website visitors</li>
               <li>Exemplary value: GA1.2.1326744211.152111097873</li>
           </ul>
       </li>
       <li>_gid<ul>
               <li>Expiration time: 24 hours</li>
               <li>Use: Differentiation of website visitors</li>
               <li>Exemplary value: GA1.2.1687193234.152111097873</li>
           </ul>
       </li>
       <li>_gat_gtag_UA_<ul>
               <li>Expiry time: 1 minute</li>
               <li>Use: Used to throttle the request rate. If Google Analytics is
                   provided
                   through Google Tag Manager, this cookie will be named
                   _dc<em>gtm</em>
               </li>
               <li>Exemplary value: 1</li>
           </ul>
       </li>
   </ul>
   <p>For more information about Terms of Use and Privacy, please visit <a
           href="https://www.google.com/analytics/terms/us.html" rel="noreferrer"
           target="_blank">www.google.com/analytics/terms/us.html</a> or <a
           href="https://support.google.com/analytics/answer/6004245?hl=en" rel="noreferrer"
           target="_blank">support.google.com/analytics/answer/6004245?hl=en</a>.</p>
   <h3 id="deactivation-of-data-collection-by-google-analytics">Deactivation of data
       collection by Google Analytics</h3>
   <p>The browser add-on to disable Google Analytics JavaScript (ga.js, analytics.js,
       dc.js) allows site visitors to prevent Google Analytics from using their data.
       You
       may prevent the collection by Google of the data generated by the cookie and
       related
       to your use of the website as well as the processing of this data by Google by
       downloading and installing the browser plug-in available under the following
       link:
       <a href="https://tools.google.com/dlpage/gaoptout?hl=en" rel="noreferrer"
           target="_blank">tools.google.com/dlpage/gaoptout?hl=en</a>.
   </p>
   <h2 id="youtube-privacy-policy">YouTube Privacy Policy</h2>
   <p>We use YouTube, the company YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066,
       USA
       on this website. By accessing pages of our website that have
       integrated YouTube videos, data is transmitted to YouTube, stored and evaluated.
       If
       you have a YouTube account and you are signed in, it will be associated with
       your
       personal account and the data stored in it. What data Google collects and how it
       uses this information can be found at <a href="https://www.google.com/intl/en/policies/privacy/"
           rel="noreferrer" target="_blank">www.google.com/intl/en/policies/privacy/</a>.</p>
</div>
</section>
