 <app-overview></app-overview>
 <app-about></app-about>
 <app-hiw></app-hiw>
 <app-homepage-video-1></app-homepage-video-1>
 <app-training></app-training>
 <app-homepage-video-2></app-homepage-video-2>
 <app-team></app-team>
 <app-resourceshome></app-resourceshome>
 <app-testimonial></app-testimonial>
 <app-homefaq></app-homefaq>



<!--fullscreen info with background image
<section class="is-fullscreen training-gradient js-scroll unroll" id="training-section">

<section class="is-fullscreen training-gradient js-scroll" id="training-section">
  <div class="custom-shape-divider custom-shape-divider-top-1666092577">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
      </svg>
  </div>
  <h1 class="section-title js-scroll fade-in" id="training-title">Training</h1>
  <div class="training-text-overlay">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
  </div>
  <div class="is-training-content">
    <div class="is-row js-scroll fade-in">
      <div class="training-circle-left-col">
        <div class="training-text-div">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
        </div>
        <div class="additional-training-circle mildbounceUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceDown">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
      </div>
    <div class="training-circle-right-col">
        <div class="additional-training-circle mildbounceUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceDown">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceDown">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceDown">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
        <div class="additional-training-circle mildbounceDown">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-shape-divider custom-shape-divider-bottom-1666102247">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>
</section>
-->

 <!--
<section class="is-fullscreen" id="news-section">
  <h1 class="section-title js-scroll fade-in" id="news-title">News and Events</h1>
   <div class="news-box">
     <div class="news-row">
         <div class="news-col-a js-scroll fade-in">
           <span class="news-dot"></span>
         </div>
         <div class="news-col-b js-scroll fade-in">
           <h2>12th October 2022</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
       </div>
     </div>

     <div class="news-row">
         <div class="news-col-a js-scroll fade-in">
           <span class="news-dot"></span>
         </div>
         <div class="news-col-b js-scroll fade-in">
           <h2>12th October 2022</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
       </div>
     </div>

     <div class="news-row">
         <div class="news-col-a js-scroll fade-in">
           <span class="news-dot"></span>
         </div>
         <div class="news-col-b js-scroll fade-in">
           <h2>12th October 2022</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
       </div>
     </div>

   </div>
</section>
-->
