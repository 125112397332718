import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AudienceContentService } from '../audienceContent.service';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AudienceContent } from '../audienceContent.model';
import { AudienceContentConfirmComponent } from '../dialogs/audienceContentConfirm.component';


@Component({
  selector: "app-audienceContent-create",
  templateUrl: "./audienceContent-create.component.html"
})

@Injectable()
export class AudienceContentCreateComponent {
  enteredTitle = "";
  enteredContent = "";

  constructor(public audienceContentService: AudienceContentService, private dialog: MatDialog) {

  }

  onAddAudienceContent(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.audienceContentService.addAudienceContent(form.value.title, form.value.content);
    this.dialog.open(AudienceContentConfirmComponent);
    form.resetForm();
  }
}
