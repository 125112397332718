<section id="resources-extra-content-section" class="is-fullscreen">


    <div class="resources-extra-content-text page-gutter-padding">
      <h1 id="resources-extra-content-title">Content</h1>
      <div>
        <p>If you have any opportunity to browse stock photo/video websites and see any content that you would love to have featured on the website, please list the URLs here. Please also put the title of the section that you wish to see the photo/video featured in, if relevant:
          Katie Riches at katie.riches@:bristol.ac.uk. We’d love to hear from you!</p>
      </div>

    <div class="stock-image-content-box">
      <div class="stock-image-content-box-item stock-image-content-form">
      <mat-card>
        <form (submit)="onAddAudienceContent(audienceContentForm)" #audienceContentForm="ngForm">
          <mat-form-field>
            <input
              matInput
              type="text"
              name="title"
              ngModel
              required
              minlength="3"
              placeholder="Add a query title"
              #title="ngModel">
            <mat-error *ngIf="title.invalid">Please enter a query title.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              rows="4"
              name="content"
              ngModel
              required
              placeholder="Add query content"
              #content="ngModel"></textarea>
            <mat-error *ngIf="content.invalid">Please enter query content.</mat-error>
          </mat-form-field>
          <button
            mat-raised-button
            color="accent"
            type="submit">Post Content</button>
        </form>
      </mat-card>
    </div>
    <p class="stock-image-content-box-item stock-image-content-text"><strong>Free stock images/videos:</strong><br>https://www.pexels.com<br>https://pixabay.com/</p>
    <p class="stock-image-content-box-item stock-image-content-text"><strong>Paid stock images/videos:</strong><br>https://www.shutterstock.com/<br>https://stock.adobe.com/uk</p>
    </div>
    </div>

  </section>
