<section class="is-fullscreen training-gradient" id="training-section">
  <div class="custom-shape-divider custom-shape-divider-top training-divider-top">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
      </svg>
  </div>

  <div class="training-content">
      <div class="training-left-col">
        <div class="page-gutter-padding">
          <h1 class="section-title-type-b" id="training-title-1">Benefits of C-Change</h1>
          <div class="training-text-div">
            <p class="benefits-body follow-text">C-Change is designed to be useful in practice. It can be flexibly integrated with existing assessment processes to support practitioners in their everyday work.  It acknowledges strengths and risks within families, encourages systemic thinking and assists with building a trusting parent-practitioner relationship.  C-Change recognises the importance of parental engagement with services while acknowledging the influence that the parent’s environment and available resources will have on parents who are trying to respond to the help offered.</p>
            <p class="benefits-body follow-text">C-Change is evidence-informed, drawing on behaviour change theories such as Self-Determination Theory (Deci and Ryan) and the COM-B approach (Michie et al), that have been extensively tested in the field of health psychology.  Research studies evaluating these models have demonstrated that individual capacity to change health and lifestyle behaviours is affected by a variety of interacting factors, ranging from social and structural circumstances to individual intentions, skills and habits.  C-Change has built on these proven behaviour change theories to provide an assessment framework that is suitable for the Children and Families workforce, which has been positively evaluated.</p>
          </div>
        </div>
      </div>

    <div class="training-right-col">

      <div class="right-col-underlay">
      <h1 class="section-title-type-b" id="training-title-2">Six Reasons to use C-Change</h1>

      <div class="training-circle-expanded" id="training-circle-expanded-a" [ngClass]="{'slide-in-animation' : slideInAnim, 'slide-out-animation' : slideOutAnim}" *ngIf="isShowDivIf">
        <button class="exit-button training-circle-expanded-exit button is-inverted is-secondary" (click)="onClick($event)">X</button>
        <div class="training-circle-expanded-text page-gutter-padding">
            <h1> {{ trainingTitle }} </h1>
            <p> {{ trainingContentPara1 }} <br><br> {{ trainingContentPara2 }} <br><br> {{ trainingContentPara3 }} <br><br> {{ trainingContentPara4 }}</p>
        </div>
      </div>

      <div class="training-expanded" [ngClass]="{'training-slide-in-right' : trainingBoxSlideIn, 'training-slide-out-right' : trainingBoxSlideOut}" *ngIf="showTrainingBox">
        <div class="training-expanded-image"><img src="/assets/images/bike.jpeg" alt="Italian Trulli"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <button class="training-expanded-exit button is-inverted is-secondary" (click)="closeTrainingBox($event)">Back</button>
      </div>

      <div class="training-buttons" [ngClass]="{'training-fade-out' : buttonFadeOut}" *ngIf="showTrainingButton">
          <div class="training-circle-holder">
           <div id= "training-circle-1" class="training-circle trainingBounceUp" (click)="onClick('buttonOne')">
               <p>Has been positively evaluated by practitioners</p>
           </div>
         </div>

           <div class="training-circle-holder">
            <div id= "training-circle-2" class="training-circle trainingBounceDown" (click)="onClick('buttonTwo')">
                <p>Can be used across Children and Families teams</p>
            </div>
          </div>

          <div class="training-circle-holder">
           <div id= "training-circle-3" class="training-circle trainingBounceUp" (click)="onClick('buttonThree')">
               <p>Can be used throughout involvement with families</p>
           </div>
         </div>

         <div class="training-circle-holder">
          <div id= "training-circle-4" class="training-circle trainingBounceDown" (click)="onClick('buttonFour')">
              <p>Can inform recommendations to the Family Courts</p>
          </div>
        </div>

          <div class="training-circle-holder">
           <div id= "training-circle-5" class="training-circle trainingBounceUp" (click)="onClick('buttonFive')">
               <p>Aids professional judgement</p>
           </div>
         </div>

         <div class="training-circle-holder">
          <div id= "training-circle-6" class="training-circle trainingBounceDown" (click)="onClick('buttonSix')">
              <p>Retains the focus on children while assessing parents</p>
          </div>
        </div>

        </div>
      </div>
    </div>
</div>

<div class="custom-shape-divider custom-shape-divider-bottom training-divider-bottom">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" style="transform: rotate(180deg);">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>

</section>


<!-- <p class="benefits-body">The C-Change approach integrates flexibly with existing assessment processes and supports:</p>
<ol class="benefits-list">
  <li>A thorough exploration and testing of parental capacity for change</li>
  <li>Clear thinking and professional judgement</li>
  <li>Identification of parental behaviours that need to change</li>
  <li>Increased knowledge and better understanding of what helps and hinders behaviour change</li>
  <li>Improved timeliness of decision-making by focussing on achieving change within child’s timescale</li>
  <li>Improvements in the quality of analysis</li>
  <li>The choice and application of interventions with a family</li>
  <li>Practitioner confidence in their assessments</li>
  <li>Court reports that are well received by judges</li>
  <li>More effective ways of working with cases that seem stuck; where change is slow in coming or not maintained</li>
  <li>Thinking about contact and reunification</li>
  <li>Potential reductions in the need for expert witnesses during court proceedings, and avoidance of delay</li>
</ol> -->
