import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-homepage-video-2',
  templateUrl: './homepage-video-2.component.html',
})
export class HomepageVideo2Component implements AfterViewInit {

  @ViewChild('playButton') playButton!: ElementRef<HTMLDivElement>;
  @ViewChild('heroVideo2') heroVideo2!: ElementRef<HTMLVideoElement>;

  constructor() { }

  ngAfterViewInit(): void {
    this.setupVideoPlayButton();
  }

  private setupVideoPlayButton(): void {
    this.playButton.nativeElement.addEventListener('click', () => {
      if (this.heroVideo2 && this.heroVideo2.nativeElement) {
        this.heroVideo2.nativeElement.play().then(_ => {
          // Playback started
          console.log('Playback started successfully');
        }).catch(error => {
          // Playback failed
          console.error('Error trying to play the video:', error);
        });
        this.playButton.nativeElement.style.display = 'none';
      }
    });
  }
}
