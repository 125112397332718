<section class="is-fullscreen" id="homepage-video-2">
  <div class="homepage-video-2-text-container" id="mid-2-text-container-1">
    <div class="page-gutter-padding">
      <h1 class="section-title-type-b" id="homepage-video-2-title">In Conversation: Developing and Using C-Change</h1>
      <p class="hello-lead lead-text">Watch the 12 minute film below which features Dr Dendy Platt and Katie Riches, developers of the C-Change approach, in discussion with Sue Corbett and Sue Gill, trainers from Interface Enterprises, to explore the benefits to practitioners of using C-Change, and the impact this can have on work with families.</p>
    </div>
  </div>

    <div id="homepage-video-2-content">
      <video #heroVideo2 playsinline controls class="homepage-video-2-Video" poster="../../../assets/images/fam10.jpg">
        <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange2.mp4" type="video/mp4">
      </video>
      <div #playButton class="play-button"></div>
      <div class="homepage-video-2-text-container" id="mid-2-text-container-2">
        <div class="page-gutter-padding">
          <p class="hello-lead lead-text"></p>
        </div>
      </div>
  </div>

  <div class="homepage-video-2-divider-bottom">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>


</section>
