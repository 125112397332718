<section class="is-fullscreen" id="fullscreenIntro">
  <div id="res-manual-circle-holder">
    <div id="res-manual-circle-a" class="about-circle res-manual-circle"><h4 class="title">Download Manual PDF</h4></div>
    <div id="res-manual-circle-b" class="about-circle res-manual-circle"><h4 class="title">Download Scottish Version</h4></div>
  </div>
  <div id="fullscreenIntroContent">
    <video playsinline autoplay muted loop id="heroVideo1" class="heroVideo">
        <source src="../assets/video/newhero.mp4" type="video/mp4">
    </video>
    <div class="strapLineDiv resourcesStrap">
      <h1 id="strapLine" class="on-start-fade-in-a">C-Change<br> Content Review</h1>
    </div>
  </div>
  <div class="resources-menu-hero-divider custom-shape-divider custom-shape-divider-bottom">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs>
              <linearGradient id="gradient" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#0CC6DD" />
                <stop offset="100%" stop-color="#0CC6DD" />
              </linearGradient>
          </defs>
          <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>
</section>

<section id="resources-overview-section" class="is-fullscreen">
  <div id="overview" class="section-overview on-start-fade-in-b">
    <div class="resources-overview-text overview-text page-gutter-padding">
      <h1 id="overview-title">Welcome to C-Change Content Review:<br></h1>
       <p class="overview-lead"><strong></strong></p>
        <p class="overview-follow"></p>
    </div>
 </div>

 <div class="custom-shape-divider custom-shape-divider-bottom res-overview-bottom-divider">
   <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
       <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
   </svg>
</div>
 </section>

<!--
  <app-audienceQuestion-create></app-audienceQuestion-create>
-->


  <div *ngIf="userIsAuthenticated && isAdmin">
    <app-audienceQuestion-list></app-audienceQuestion-list>
  </div>


  <div *ngIf="!isAdmin">
    <h1 id="overview-title">Admin only content<br></h1>
  </div>
