<section id="resources-section">
  <div class="resourcesHome-top-divider">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
  </div>
  <h1 class="section-title-type-b  page-gutter-padding" id="resources-title">Introducing C-Change to Your Organisation</h1>
    <div class="resources-box">
      <div id="resources-homepage-description" class="more-button ">
        <p class = "follow-text">The University of Bristol offers training and consultation via our partner training organisation, Interface Enterprises.
          Training for practitioners is based on a two-day programme and is available face to face or facilitated online. Training
          can be purchased specific for your organisation, or attended on an open basis alongside practitioners from other organisations.</p>
          <p class = "follow-text">Please visit the Interface website to find out more: <a href="https://www.interfaceenterprises.co.uk/training">https://www.interfaceenterprises.co.uk/training</a></p>
          <p class = "follow-text">If you have already been signed up to the training programme, click the button below to get started with our resources.</p>
        </div>
      <div id="resources-button" class="more-button ">
         <h2 routerLink="/resourcesmenu">Get Started</h2>
       </div>
      </div>
      <div class="resourcesHome-bottom-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
    </div>
</section>
