 import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-resourceshome",
  templateUrl: './resourceshome.component.html'
})
export class ResourcesHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
