 import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: "app-training",
  templateUrl: './training.component.html'
})
export class TrainingComponent implements OnInit {

  buttonFadeIn = false;
  buttonFadeOut = false;
  trainingBoxSlideIn = false;
  trainingBoxSlideOut = false;
  showTrainingButton = true;
  showTrainingBox = false;

  isShowDivIf = false;
  slideInAnim = false;
  slideOutAnim = false;
  trainingTitle = "Test Title";
  trainingContentPara1 = "Test Content";
  trainingContentPara2 = "Test Content";
  trainingContentPara3 = "Test Content";
  trainingContentPara4 = "Test Content";

  constructor(private renderer:Renderer2) {


  }

  ngOnInit(): void {

  }

  onClick(data) {

    if(data=='buttonOne') {
      this.trainingTitle = "C-Change has been positively evaluated by practitioners";
      this.trainingContentPara1 = "An evaluation of C-Change was undertaken in 2015 involving 107 social work practitioners (Platt et al. 2020). The evaluation found that C-Change was well-used by practitioners and their supervisors. There were improvements in their self-reported knowledge and skills in assessing capacity to change, and there was some evidence that C-Change may support improvements in decision-making.";
      this.trainingContentPara2 = '';
      this.trainingContentPara3 ='';
      this.trainingContentPara4 ='';
    }

    else if(data=='buttonTwo') {
      this.trainingTitle = "C-Change can be used across Children and Families teams";
      this.trainingContentPara1 = "  C-Change has been used with families being supported by Early Help, through Child In Need plans and Child Protection plans.  It remains applicable once a letter before proceedings is issued or during care proceedings (in the English legal context).";
      this.trainingContentPara2 = " Practitioners have used the approach when children are living away from their parents to inform contact arrangements, or support foster carers.";
      this.trainingContentPara3 = " C-Change can help thinking about reunification by considering whether change is likely to continue into the future.";
      this.trainingContentPara4 ='';
    }

    else if(data=='buttonThree') {
      this.trainingTitle = "C-Change can be used throughout involvement with families";
      this.trainingContentPara1 = "At the beginning of involvement, it can help to identify parental behaviours that need to change.";
      this.trainingContentPara2 = "When considering which services to offer, it can inform the choice of interventions to use with a family by focusing attention on building on facilitators for change or tackling barriers.";
      this.trainingContentPara3 = "If cases seem stuck, where change is slow in coming or not maintained, C-Change provides effective ways of unpicking what it is that is keeping families stuck by thoroughly exploring the barriers for change.";
      this.trainingContentPara4 = "If intervention has reached court proceedings, C-Change can provide a framework for use during parenting assessments for the Family Court.";
    }

    else if(data=='buttonFour') {
      this.trainingTitle = "C-Change can inform recommendations to the Family Courts";
      this.trainingContentPara1 = " It provides a thorough exploration and testing of parental capacity for change which helps all parties involved to understand whether change is likely.";
      this.trainingContentPara2 = "Court reports that have used C-Change have been well received by judges";
      this.trainingContentPara3 = "C-Change encourages critical thinking and high quality analysis.  Having these within social work court reports could potentially reduce the need for expert witnesses during court proceedings, and avoid delay.";
      this.trainingContentPara4 ='';
    }

    else if(data=='buttonFive') {
      this.trainingTitle = "C-Change aids professional judgement";
      this.trainingContentPara1 = "Feedback from practitioners includes: ";
      this.trainingContentPara2 = "1. Increased knowledge and better understanding of what helps and hinders behaviour change";
      this.trainingContentPara3 = "2. Improvements in the quality of analysis";
      this.trainingContentPara4 = "3. More confidence in their assessments";
    }

    else if(data=='buttonSix') {
      this.trainingTitle = "C-Change retains the focus on children while assessing parents";
      this.trainingContentPara1 = "Helping practitioners to understand whether parents can change for the benefit of their children is at the heart of C-Change.";
      this.trainingContentPara2 = "The cumulative and negative effects of abuse or neglect on children are increasingly understood and C-Change recommends that the timetable for parental change is driven by the children’s needs.";
      this.trainingContentPara3 = "  Practitioners using C-Change reported improved timeliness of decision-making by focussing on achieving change within child’s timescale.";
      this.trainingContentPara4 ='';
    }

    if(!this.isShowDivIf) {
      console.log(data);
      this.slideInAnim = true;
      this.isShowDivIf = true;
    }


    else if(this.isShowDivIf) {
      this.slideInAnim = false;
      this.slideOutAnim = true;
      setTimeout (() => {
             console.log("okay bye");
             this.isShowDivIf = false;
             this.slideOutAnim = false;
          }, 1000);
    }
  }















  trainingSlideBoxButton(data) {

/*
    this.buttonFadeOut = true;

    setTimeout (() => {
      this.showTrainingButton = false;
      this.showTrainingBox = true;
      this.trainingBoxSlideIn = true;
      this.buttonFadeOut = false;
    }, 1000);
    */
  }

  closeTrainingBox(data) {
    this.trainingBoxSlideOut = true;

    setTimeout (() => {
      this.buttonFadeIn = true;
      this.showTrainingButton = true;
      this.trainingBoxSlideIn = false;
      this.trainingBoxSlideOut = false;
      this.showTrainingBox = false;
    }, 1000);
  }

  resetTrainingBox() {

  }

}
