
<section id="hiw-section" class="is-fullscreen">
  <div class="custom-shape-divider custom-shape-divider-top hiw-top-divider">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>

  <div class="hiw-overview">
    <h1 class="section-title-type-b page-gutter-padding" id="hiw-title">How it Works</h1>
<!--     <h2 class="page-gutter-padding lead-text">What are a parent’s barriers and facilitators to change?</h2> -->
    <p class="page-gutter-padding lead-text">The C-Change approach focuses specifically on assessing parental capacity to change, with the aim of better informing future planning and decision-making for the child. It is designed to be used alongside standard methods of assessing children and their families, such as the Framework for the Assessment of Children in Need, Signs of Safety, or other equivalent approaches.  The needs assessment will consider the strengths and areas of need within a family, and identify the parenting behaviours that need to change to ensure the child’s well-being. It is at this point that C-Change can lead practitioners through a thorough exploration of the parent’s capacity to change those behaviours, at this moment in time.<br><br>A C-Change assessment combines two essential elements, understanding and action:
    </p>
  </div>

  <div class="hiw-row">

    <div class="hiw-col" id="hiw-col-a" *ngIf="showLeftCol">
        <div class="buttons">

        <div class="hiw-row-col" id="col-a-box-1">
          <div id="hiw-circle-a" class="hiw-circle hiwCircleBounceUp" [ngClass]="{'hiw-button-mobile-fade-in' : buttonFadeIn, 'hiw-button-mobile-fade-out' : buttonFadeOut}" (click)="slideBoxChooser('topButton')" *ngIf="showButtons">
            <h1>Understanding: What are a parent’s barriers and facilitators to change?</h1>
          </div>
        </div>

        <div class="hiw-row-col" id="col-a-box-2">
          <div id="hiw-circle-b" class="hiw-circle hiwCircleBounceDown" [ngClass]="{'hiw-button-mobile-fade-in' : buttonFadeIn, 'hiw-button-mobile-fade-out' : buttonFadeOut}" (click)="slideBoxChooser('middleButton')" *ngIf="showButtons">
            <h1>Action: Assessing actual changes</h1>
          </div>
        </div>

        <div class="hiw-row-col" id="col-a-box-3">
          <div id="hiw-circle-c" class="hiw-circle hiwCircleBounceUp" [ngClass]="{'hiw-button-mobile-fade-in' : buttonFadeIn, 'hiw-button-mobile-fade-out' : buttonFadeOut}" (click)="slideBoxChooser('bottomButton')" *ngIf="showButtons">
            <h1>6 Principles of C-Change</h1>
          </div>
        </div>
      </div>
    </div>


    <div class="hiw-col" id="hiw-col-b" [ngClass]="{'slide-in-hiw-box-mobile-animation' : slideInFullPane, 'slide-out-hiw-box-mobile-animation' : slideOutFullPane}" *ngIf="showRightCol">
      <div class="hiw-row-col" id="col-b-box-1">
        <div id="hiw-circle-expanded-text-a" class="hiw-circle-expanded-text" [ngClass]="{'slide-in-animation' : slideInTopAnim, 'slide-out-animation' : slideOutTopAnim}" *ngIf="showTopBox">
          <button class="exit-button hiw-circle-expanded-exit button is-inverted is-secondary" (click)="closeAllBoxes($event)">X</button>
          <h1>Understanding: What are a parent’s barriers and facilitators to change?</h1>
          <p>C-Change provides a framework of barriers to and facilitators of change drawn from theories of behaviour change and adapted to the context of children and families social work.</p>
          <p>The five categories of barriers or facilitators are Priority and Relevance, Knowledge and Skills, Motivation and Intentions, Habits, and Contextual Factors.</p>
          <p>The C-Change approach describes and explains each of these categories using practice examples, supported by theory and research. Practitioners are offered practical suggestions and supporting materials to guide them through exploring relevant issues with families.</p>
          </div>
        <!--  <h1> {{ hiwTitle }} </h1>
          <p> {{ hiwContent }} </p> -->
      </div>

      <div class="hiw-row-col" id="col-b-box-2">
        <div id="hiw-circle-expanded-text-b" class="hiw-circle-expanded-text" [ngClass]="{'slide-in-animation' : slideInMiddleAnim, 'slide-out-animation' : slideOutMiddleAnim}" *ngIf="showMiddleBox">
          <button class="exit-button hiw-circle-expanded-exit button is-inverted is-secondary" (click)="closeAllBoxes($event)">X</button>
          <h1>Action: Assessing actual changes</h1>
          <p>C-Change advocates using the technique of Goal Attainment Scaling to assess the significance of changes made by parents. Goal Attainment Scaling provides a careful and thorough approach to
            setting goals and reviewing progress. It offers clarity in identifying what is acceptable behaviour in terms of ensuring the child’s safety and well-being.  Goal Attainment Scaling can offer
            clarity and opportunities for collaboration with parents, while working in tandem with existing processes for agreeing goals and plans.  Reports from the pilot evaluation of C-Change suggest
            that parents have found it useful and accessible, as have social workers.</p>
          <p>In our adoption of Goal Attainment Scaling we have drawn on the work of Paul Harnett (developer of the Parents Under Pressure Programme) who introduced the technique to work with parents in his research and practice.</p>
          <p>Alternative practical strategies for assessing actual change are offered, including observation and/or measurement of parents’ behaviours, both before and after an intervention.</p>
        </div>
      </div>

      <div class="hiw-row-col" id="col-b-box-3">
        <div id="hiw-circle-expanded-text-c" class="hiw-circle-expanded-text" [ngClass]="{'slide-in-animation' : slideInBottomAnim, 'slide-out-animation' : slideOutBottomAnim}" *ngIf="showBottomBox">
          <button class="exit-button hiw-circle-expanded-exit button is-inverted is-secondary" (click)="closeAllBoxes($event)">X</button>
          <h1>6 Principles of C-Change</h1>
          <ul>
          <li>C-Change must be integrated with existing thorough assessment processes covering children’s needs, parenting capacity and environmental factors.</li>
          <li>Risks to the children must have been clearly identified and described.</li>
          <li>Capacity to change must be assessed in relation to particular behaviours that need to change.</li>
          <li>All relevant parents or carers should have their capacity to change assessed separately but with attention to the dynamics between them.</li>
          <li>There must be equal attention paid to understanding the barriers and facilitators of change AND to observing actual changes to the parent’s behaviour. Information on both elements should be brought together in analysis.</li>
          <li>Parents need to change for their children. Assessment must consider whether the parent has the capacity to achieve change within the timescale needed by their child.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="custom-shape-divider custom-shape-divider-bottom hiw-bottom-divider">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
   </div>

  </div>


</section>
