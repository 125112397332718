import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';

import { AudienceQuestion } from "../audienceQuestion.model";
import { AudienceQuestionService } from '../audienceQuestion.service';


@Component({
  selector: "app-audienceQuestion-list",
  templateUrl: "./audienceQuestion-list.component.html"
})
export class AudienceQuestionListComponent implements OnInit, OnDestroy {
  // posts = [
  //   { title: "First Post", content: "This is the first post's content" },
  //   { title: "Second Post", content: "This is the second post's content" },
  //   { title: "Third Post", content: "This is the third post's content" }
  // ];

  audienceQuestions: AudienceQuestion[] = [];
  private audienceQuestionsSub: Subscription;

  posts = [
    {title: "Hello eh, I am question #QQ1", content: "Thanks, I am answer #1"},
    {title: "Hello eh, I am question #QQ2", content: "Thanks, I am answer #2"},
    {title: "Hello eh, I am question #QQ3", content: "Thanks, I am answer #3"},
  ]

  constructor(public audienceQuestionService: AudienceQuestionService) {}

  ngOnInit() {
//    this.faqs = this.faqService.getFaqs();
    this.audienceQuestionService.getAudienceQuestions();
    this.audienceQuestionsSub = this.audienceQuestionService.getAudienceQuestionUpdateListener()
    .subscribe((audienceQuestions: AudienceQuestion[]) => {
      this.audienceQuestions = audienceQuestions;
    });
  }

  ngOnDestroy() {
    this.audienceQuestionsSub.unsubscribe();
  }


}
