import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: "app-about",
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

  isShowDivIf = false;
  slideInAnim = false;
  slideOutAnim = false;
  aboutTitle = "Test Title";
  aboutContent = "Test Content";

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void { }

  onClick(data) {

    if(data == 'leftButton') {
      this.aboutTitle = "What is parental capacity to change?";
      this.aboutContent = "We see parental capacity to change as the range of attributes, capabilities, motivations, opportunities and contextual factors that may enable a parent to make changes for the benefit of their children, and to demonstrate that they can address critical difficulties that would otherwise have a severe impact on their child’s welfare.  It is a complex and dynamic interaction between personal characteristics and environmental features and deserves thorough exploration.";
    }

    else if(data == 'midButton') {
      this.aboutTitle = "Why is parental capacity for change important?";
      this.aboutContent = "Understanding whether a parent has the capacity to make changes to their behaviour or environment is a fundamental part of assessing whether those parents are likely to be able to meet the needs of their children in the future.  A thorough exploration of parental capacity for change provides practitioners with better information for planning and decision-making and provides parents with a fair opportunity to demonstrate their capacity.";
    }

    else if(data == 'rightButton') {
      this.aboutTitle = "Is C-Change evidence-informed?";
      this.aboutContent = "Yes! C-Change draws on behaviour change theories such as Self-Determination Theory (Deci and Ryan) and the COM-B approach (Michie et al), that have been extensively tested in the field of health psychology.  Research studies evaluating these models have demonstrated that individual capacity to change health and lifestyle behaviours is affected by a variety of interacting factors, ranging from social and structural circumstances to individual intentions, skills and habits. C-Change has built on these behaviour change theories to provide an assessment framework that is suitable for the Children and Families workforce.";
    }

    if (!this.isShowDivIf) {
      this.slideInAnim = true;
      this.isShowDivIf = true;
    } else {
      this.slideInAnim = false;
      this.slideOutAnim = true;
      setTimeout(() => {
        this.isShowDivIf = false;
        this.slideOutAnim = false;
      }, 500); // Match the timeout duration with the CSS transition duration
    }
  }
}
