<section class="is-fullscreen" id="fullscreenIntro">
  <div id="res-manual-circle-holder">
    <div id="res-manual-circle-a" class="about-circle res-manual-circle"><a href="../assets/docs/cchangemanual.pdf"><h4 class="title">Download Manual PDF</h4></a></div>
    <div id="res-manual-circle-b" class="about-circle res-manual-circle"><a href="../assets/docs/cchangemanualscottish.pdf"><h4 class="title">Download Scottish Version</h4></a></div>
  </div>
  <div id="fullscreenIntroContent">
    <video playsinline autoplay muted loop id="heroVideo1" class="heroVideo">
        <source src="../assets/video/newhero.mp4" type="video/mp4">
    </video>
    <div class="strapLineDiv resourcesStrap">
      <h1 id="strapLine" class="on-start-fade-in-a">C-Change<br> Resources</h1>
    </div>
  </div>
  <div class="resources-menu-hero-divider custom-shape-divider custom-shape-divider-bottom">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs>
              <linearGradient id="gradient" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#0CC6DD" />
                <stop offset="100%" stop-color="#0CC6DD" />
              </linearGradient>
          </defs>
          <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>
</section>

<section id="resources-overview-section" class="is-fullscreen">
  <div id="overview" class="section-overview on-start-fade-in-b">
    <div class="resources-overview-text overview-text page-gutter-padding">
      <h1 id="overview-title">Welcome to C-Change Resources:<br>Overview</h1>
       <p class="overview-lead"><strong>This section of the website is designed to help you refresh your memory about the C-Change approach to assist you in using C-Change
         in your practice.  Each element is designed to be accessed independently to suit your learning needs but they have been ordered in a way that mirrors the C-Change
         assessment process and training programme.</strong></p>

        <p class="overview-follow">Please note that these resources are intended as a recap for practitioners who have completed the C-Change training with Interface Enterprises
          and can not replicate the depth of learning experienced during training.</p>
        <p class="overview-follow">Under each title you will find:</p>
          <ul class="overview-follow">
          <li>A short film describing that element of the C-Change approach</li>
          <li>Reflective exercises designed to get you back into the C-Change mindset</li>
          <li>Signposts / links to the relevant chapter of the C-Change manual</li>
          </ul>
    </div>
 </div>

 <div class="custom-shape-divider custom-shape-divider-bottom res-overview-bottom-divider">
   <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
       <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
   </svg>
</div>
 </section>

<section id="resourcesmenu-section">
    <h1 class="page-gutter-padding" id="resourcesmenu-section-heading">Resources Menu</h1>

    <!-- <div id="resourcesmenu-login-message" *ngIf="!userIsAuthenticated"> -->
    <div id="resourcesmenu-login-message" *ngIf="!userIsAuthenticated">
        <h2 routerLink="/login" class="more-button resourcesmenu-login-message-button" id="resourcesmenu-login-message-button1">If you are already registered, please login to view resources.</h2>
        <h2 routerLink="/signup" class="more-button resourcesmenu-login-message-button" id="resourcesmenu-login-message-button1">If you are not registered, please signup here.</h2>
    </div>

    <!-- <div id="resourcesmenu-accordion" *ngIf="userIsAuthenticated"> -->
    <div id="resourcesmenu-accordion" *ngIf="userIsAuthenticated">
      <mat-accordion multi="true">

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Defining Capacity for Change</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo1" class="resources-video" poster="../assets/images/fam1.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange3.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Defining Capacity for Change</h3>
                  <p class="resources-text">This film covers the C-Change definition of parental capacity to change and explores different elements of engagement. By doing so, the difference between engagement and capacity for change is made clear.</p>
                  <p class="resources-text">Relevant chapter in the C-Change manual: Chapter 2 (pg. 13)</p>
                  <p class="resources-text"><strong>Reflective question: </strong>Changes in engagement can be a step towards changes in parenting, or may be where change ends. How can you maintain your focus on whether behaviour is changing alongside engagement?</p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Explore Barriers and Facilitators of change (The Petals)</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo2" class="resources-video" poster="../assets/images/fam2.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange4.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Explore Barriers and Facilitators of change (The Petals)</h3>
                  <p class="resources-text">This film explains each of the five factors within the C-Change framework that influence change; Priorities, Knowledge and Skills, Motivation and Intentions, Habits and Automatic Reactions, Contextual Factors.  A few examples are provided of how these factors can act as barriers or facilitators for behavioural change.</p>
                  <p class="resources-text">Relevant chapter in the C-Change manual: Chapter 3 (pg. 19) and Chapter 4 (pg. 31)</p>
                  <p class="resources-text"><strong>Reflective question: </strong>Think about a change that you would like to make in your life. How do the various factors influence your capacity to make that change? What would be different if it was a change that you did not want to make but felt you had to for someone else?</p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Assess Actual Change: Using Goal Attainment Scaling</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo3" class="resources-video" poster="../assets/images/fam3.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange5.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Assess Actual Change: Using Goal Attainment Scaling</h3>
                  <p class="resources-text">This film explains the concept and process of Goal Attainment Scaling, and uses a worked example to illustrate how this could apply to a family situation.</p>
                  <p class="resources-text">Relevant chapter in the C-Change manual: Chapter.</p>
                  <p class="resources-text"><strong>Reflective question: </strong>Think about an existing goal for a family you are working with.  Have a go at writing a concrete description of how the behaviour or situation would look at the level of being successful (a score of 3). Reflect on that description and consider whether the level of change described would be meaningful for you (and the child/ren) and manageable for the parent(s).</p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Analyse the information gained within a C-Change assessment</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo4" class="resources-video" poster="../assets/images/fam4.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange6.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Analyse the information gained within a C-Change assessment</h3>
                  <p class="resources-text">This films talks through the process of how to analyse the different aspects of the C-Change assessment, bringing together and weighing up information on the barriers and facilitators and from the measurements of actual change.  It discusses how to respond to the central issue of what the parent’s potential for change is and how this corresponds to the timeframe in which their child needs change to happen.</p>
                  <p class = "resources-text">Relevant chapter in the C-Change manual: Chapter</p>
                  <p class="resources-text"><strong>Reflective question: </strong>How can you tell which of the barriers / facilitators are having the strongest influence on a parent’s capacity for change? Do you find yourself giving greater weight to the observable changes that parents have made or to the barriers / facilitators that will continue to help or hinder them to make those changes?</p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Principles of C-Change</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo5" class="resources-video" poster="../assets/images/fam5.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange7.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Principles of C-Change</h3>
                  <p class = "resources-text">In this short film, Katie outlines the main principles of the C-Change approach. It may be useful if you’d like a reminder of the ethos of the approach, or you are thinking about how to incorporate assessments of parental capacity for change with other assessments used in your team.</p>
                  <p class = "resources-text">Relevant chapter in the C-Change manual: Chapter 2</p>
                  <p class="resources-text"><strong>Reflective question: </strong>What would help you to use the principles of C- Change in your assessments of families? Is there anything preventing you from using the C-Change approach? What one thing could you do over the next month to incorporate C-Change thinking into your assessments?</p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel class="resourcesmenu-accordion-panel">
                <mat-expansion-panel-header class="resourcesmenu-accordion-panel-header">
                  <h3>Theoretical Background</h3>
                </mat-expansion-panel-header>
                <div class = "resources-content">
                  <video playsinline controls id="resourceVideo6" class="resources-video" poster="../assets/images/fam6.jpg">
                      <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange8.mp4" type="video/mp4">
                  </video>
                  <h3 class="resources-text-para-title">Theoretical Background</h3>
                  <p class = "resources-text">This film covers the ‘science’ bit!</p>
                  <p class = "resources-text">If you’re interested in finding out more about the theories that underpin the C-Change approach then this is for you.</p>
                  <p class = "resources-text">Relevant chapter in the C-Change manual: Chapter 8</p>
                  <p class="resources-text"><strong>Reflective question: </strong>These theories are mainly drawn from health psychology relating to behaviour change.  Are there any issues with transferring these into social work with children and families? What can social work theories and approaches to add to an understanding of parental capacity for change?</p>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
    </div>
  </section>

  <!-- <section id="case-study-section" class="is-fullscreen" *ngIf="userIsAuthenticated"> -->
  <section id="case-study-section" class="is-fullscreen" *ngIf="userIsAuthenticated">
    <div class="custom-shape-divider custom-shape-divider-top hiw-top-divider">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
    </div>

    <h1 class="" id="resourcescasestudy-section-heading">Case Study Examples</h1>

    <div class="case-study-box">
        <div id="case-study-box-1" class="case-study-item">
          <img src="../assets/images/case-study-1.png">
          <div class="case-study-title"><h2>Case Study: Penni and Joya</h2></div>
          <p>This case study from the C-Change Practice Approach delves into the intricate dynamics between Penni and her infant daughter Joya, highlighting the critical need to understand and navigate barriers to change in parenting. Penni's journey, marred by her own experiences of neglect and inadequate coping mechanisms, underscores the complexities of altering deeply ingrained behaviors. Despite her acknowledgment of these issues and an ability to learn new skills, the lack of specificity and clarity from Children’s Services regarding expected changes posed significant challenges. The case illustrates the structured approach C-Change offers in identifying barriers, assessing a parent's capacity for change, and implementing tailored interventions. Through exploring Penni's past and its impact on her current parenting, this case study showcases the potent mix of personal, social, and environmental factors that can hinder or facilitate change, culminating in a decisive intervention to ensure Joya's safety. It underscores the importance of a nuanced, evidence-based approach in social work, capable of supporting families through complex, multifaceted issues.</p>
          <div id="team-more-button-holder-d" class="more-button-holder"><div id="team-more-button-d" class="more-button"><a href="../assets/docs/cs1.pdf"><h3>Read More</h3></a></div></div>
        </div>

        <div id="case-study-box-2" class="case-study-item">
          <img src="../assets/images/case-study-2.png">
          <div class="case-study-title"><h2>Case Study Two: Sasha and James</h2></div>
          <p>This case study features Sasha and her two-year-old son James, exploring the use of Goal Attainment Scaling (GAS) to address challenges in parenting. Sasha had already experienced her children being permanently removed from her care, and this history, alongside Sasha's fluctuating ability to meet James's needs raised concerns for Children’s Services. The study delves into the barriers to effective parenting faced by Sasha, including her struggle with her relationship dynamics and her own experiences of being parented. It highlights how the structured application of GAS focused on improving Sasha's communication with James, marking a positive step towards change. This method of goal setting, used within the C-Change approach, provided clarity on Sasha's ability to make actual changes, which informed intervention planning and contributed to a realistic assessment of her ability to provide safe care for James. This case study reinforces the importance of specific, measurable and meaningful goals in complex situations.</p>
        <div id="team-more-button-holder-d" class="more-button-holder"><div id="team-more-button-d" class="more-button"><a href="../assets/docs/cs2.pdf"><h3>Read More</h3></a></div></div>
        </div>

        <div id="case-study-box-3" class="case-study-item">
          <img src="../assets/images/case-study-3.png">
          <div class="case-study-title"><h2>Case Study Three: Caron</h2></div>
          <p>This case study illustrates the application of the C-Change Practice Approach to address a stuck case involving Caron and her children, who were being negatively impacted by Caron's use of threats and physical discipline. The situation was compounded by Caron's previous negative experiences with Children's Social Care and current resistance to allowing any assessments or interventions. By following the principles of the C-Change approach, a non-confrontational strategy was employed to understand and navigate the barriers and facilitators affecting Caron's ability to engage. Exploring these elements in depth with her social worker helped Caron recognise her attitudes towards social workers as a primary obstacle to change, paving the way for an agreed plan to work to 'good enough' parenting. The case highlights the power of empathy, reflective practice, and a structured framework in transforming a resistant situation into one where positive change is possible, demonstrating the effectiveness of C-Change in empowering parents and facilitating constructive engagement with Children’s Services.</p>
        <div id="team-more-button-holder-d" class="more-button-holder"><div id="team-more-button-d" class="more-button"><a href="../assets/docs/cs3.pdf"><h3>Read More</h3></a></div></div>
        </div>

        <div id="case-study-box-4" class="case-study-item">
          <img src="../assets/images/case-study-4.png">
          <div class="case-study-title"><h2>Case Study Four: Ella and Dan</h2></div>
          <p>This case study explores the utilisation of the C-Change Practice Approach in supervisory settings, specifically focusing on the case of Ella, a 10-year-old girl cared for by her father, Dan, amidst challenging family dynamics. With Ella's mother hospitalised and Dan's older children involved in local gang culture, there were significant concerns about Ella's welfare and Dan's parenting were paramount. The application of C-Change in supervision facilitated a nuanced exploration of Dan's capacity for change, challenging initial impressions of his motivation and identifying both risks and strengths in his parenting approach. By focusing on Dan's values and his role as a father, the supervision process underscored the importance of targeted interventions, leveraging facilitators of change, and planning protective measures for Ella. This detailed, evidence-supported approach illustrates how C-Change can guide supervision in Children’s Services, promoting a more profound understanding of familial situations and enhancing decision-making for child welfare.</p>
        <div id="team-more-button-holder-d" class="more-button-holder"><div id="team-more-button-d" class="more-button"><a href="../assets/docs/cs4.pdf"><h3>Read More</h3></a></div></div>
        </div>
   </div>
   <div class="resources-case-study-bottom-divider custom-shape-divider custom-shape-divider-bottom">
       <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
           <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
       </svg>
   </div>
  </section>

<!--
    <section id="share-experience-section" class="is-fullscreen">
    <div class="share-experience-text page-gutter-padding">
        <h1 id="share-experience-title">Share your experiences</h1>
    <div>
      <p>We would really like to encourage you to give us feedback about the C-Change approach or about your experiences of working with parents who need to change, and of applying C-Change.
        Please use this space to anonymously share your experiences, comments, criticisms and questions. We will use your feedback to further improve the website and develop relevant FAQs.</p>
      <p>If you would like an individual reply to a question or comment, or to engage in a 1:1 dialogue about parental capacity to change then please email
          Katie Riches at katie.riches@:bristol.ac.uk. We’d love to hear from you!</p>
    </div>

    <div class="share-experience-content-box">
      <div id="" class="share-experience-content-box-item">
          <mat-card>
            <form>
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  name="title"
                  ngModel
                  required
                  minlength="3"
                  placeholder="Add a query title"
                  #title="ngModel">
                <mat-error *ngIf="title.invalid">Please comment here.</mat-error>
              </mat-form-field>
              <button
                mat-raised-button
                color="accent"
                type="submit">Post Your Comment</button>
            </form>
          </mat-card>
      </div>
    </div>
  </div>
  <div class="resources-share-experience-bottom-divider custom-shape-divider custom-shape-divider-bottom">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs>
              <linearGradient id="gradient" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#0CC6DD" />
                <stop offset="100%" stop-color="#0CC6DD" />
              </linearGradient>
          </defs>
          <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
  </div>
  </section>
-->


  <section id="resources-overview-section-mid">
    <div id="overview-mid">
      <div class="page-gutter-padding resources-overview-text">
        <h1 id="overview-title-mid">How fundamental to C-Change are the suggested questionnaires?</h1>
         <p class="overview-lead-mid"><strong>C-Change is designed as a flexible approach so that practitioners can use all their skills and tools that are available in their practice toolkit.  The tools and questionnaires we have suggested are free and easy to use and widely available but are not essential to implementing the C-Change approach.  Practitioners who helped us to develop C-Change told us it was really important to them that they have some autonomy over ‘how’ they used the C-Change assessment and we respect this.</strong></p>
          <p class="overview-follow-mid">That said, structured questionnaires or tools can be a really helpful way of crystallising the situation at the start of an assessment, and providing a clear measure that can then be repeated later on to see what has changed.  Research also suggests that assessments which use structured tools are of a higher quality than those that privilege verbal interviews. However, there are not tools for every issue or circumstance. A minimum requirement is there is some description of the behaviour that needs to change at the beginning of assessment.  Without that, it is very difficult to reliably evidence changes that have occurred.  Observation is an excellent means of doing this.</p>
      </div>
   </div>
   </section>

   <section id="resources-extra-list-section" class="is-fullscreen">

     <div class="custom-shape-divider custom-shape-divider-top hiw-top-divider">
         <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
             <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill-resources-extra-list-section"></path>
         </svg>
     </div>

     <div class="resources-extra-list page-gutter-padding">
        <div class="section-overview on-start-fade-in-b">
          <h1 id="resources-extra-list-title">Additional Resources</h1>
        </div>

        <div class="resources-extra-list-text">
          <div class="resources-extra-list-text-box">
            <h2 class="">C-Change and other relevant University of Bristol publications</h2>
            <ul>
              <li>Platt, D., Riches, K. A., Wijedasa, D. N., & Wood , M. (2020). Assessment of Parental Capacity to Change where Children may be At Risk of Harm: Evaluation of the C-Change pilot project. Children and Youth Services Review, 117, [105265]. https://doi.org/10.1016/j.childyouth.2020.105265  Open access via: https://research-information.bris.ac.uk/ws/files/250077878/Platt_Riches_Wijedasa_Wood_2020_author_accepted_manuscript_1_.pdf</li>
              <li>Platt D. & Riches K. (2016), Assessing Parental Capacity to Change: The missing jigsaw piece in the assessment of a child’s welfare? Children and Youth Services Review, Volume 61, pages 141–148.  Open access via: https://research-information.bris.ac.uk/ws/portalfiles/portal/57699680/Assess_Parental_Cap_to_Ch_Final_Accepted.pdf</li>
              <li>Platt D. & Turney D. (2014), Making Threshold Decisions in Child Protection: A conceptual analysis. British Journal of Social Work, vol 44 pp. 1454-1471.</li>
              <li>Platt D. (2012), Understanding Parental Engagement with Child Welfare Services: An Integrated Model.  Child and Family Social Work, vol 17, pp. 138-148.</li>
              <li>Turney, D, Platt, D, Selwyn, J & Farmer, E (2012), Improving Child and Family Assessment: Turning research into practice.  London: Jessica Kingsley Publishers.</li>
              <li>Platt, D. (2007). Congruence and Co-operation in Social Workers’ Assessments of Children in Need. Child and Family Social Work, 12, 326-335.</li>
            </ul>
          </div>
          <div class="resources-extra-list-text-box">
            <h2 class="">Other resources</h2>
            <ul>
              <li>Ward, H., Brown, R., & Hyde-Dryden, G. (2014). Assessing Parental Capacity to Change when Children are on the Edge of Care: an overview of current research evidence. London: Centre for Child and Family Research, Loughborough University and Department for Education.  Available to download from www.gov.uk/government/publications using reference: DfE RR 369.</li>
              <li>Williams, B., Peart, E., Young, R. & Briggs D. (eds.) (2015), Capacity to Change: Understanding and assessing a parent's capacity to change within the timescales of the child. Bristol: Family Law</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="resources-share-experience-bottom-divider custom-shape-divider custom-shape-divider-bottom">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <defs>
                  <linearGradient id="gradient" x2="0%" y2="100%">
                    <stop offset="0%" stop-color="#FF8C42" />
                    <stop offset="100%" stop-color="#FF8C42" />
                  </linearGradient>
              </defs>
              <path fill="url(#gradient)" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
          </svg>
      </div>
    </section>

    <app-resourcesfaq></app-resourcesfaq>

      <!--<app-audienceQuestion-create></app-audienceQuestion-create> -->

    <app-audienceQuestion-create *ngIf="userIsAuthenticated"></app-audienceQuestion-create>


    <!--
    <app-audienceContent-create></app-audienceContent-create> -->
