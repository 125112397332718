<section id="cchange-team">
  <h1 class="section-title-type-b page-gutter-padding" id="team-title">Meet the C-Change Team</h1>
  <div id="bio-grid-box" class="bio-grid">

    <div class="bio-element" [ngClass]="{'expanded': readMore1}">
      <div class="bio-element-image">
        <img src="../../../assets/images/kriches.png">
      </div>
      <div class="bio-element-text">
        <h2>Katie Riches</h2>
        <p class="bio-para">
          Katie qualified as a social worker in 2004 and has worked in a variety of Local Authority Children’s Services teams across the South of England. In 2014, she was completing intensive assessments of parents within the community for a Local Authority, and trying to understand the potential for change...
          <span class="extra-content">
            This work led her to the University of Bristol where she worked alongside Dendy in developing the C-Change approach to assessing parental capacity to change. During and since the development of the approach, Katie has delivered training in the approach to social workers and Early help practitioners in local authority teams across the U.K. Katie is currently working on her PhD with the University of Bristol which is exploring how parental capacity for change is understood and assessed in daily practice.
          </span>
        </p>
        <div class="more-button-holder" (click)="readMore(1)">
          <div class="more-button">
            <h3>{{ readMore1 ? 'Read Less' : 'Read More' }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="bio-element" [ngClass]="{'expanded': readMore2}">
      <div class="bio-element-image">
        <img src="../../../assets/images/jsymonds.jpg">
      </div>
      <div class="bio-element-text">
        <h2>Dr Jon Symonds</h2>
        <p class="bio-para">
          Jon is a Senior Lecturer in Social Work with Children and Families at the University of Bristol. Jon qualified as a social worker in 2006 and practised in family and parenting services before joining the University of Bristol in 2010...
          <span class="extra-content">
            Jon worked under the supervision of Dendy to complete his PhD which examined parental engagement with parenting services and has followed the progress of the C-Change project since its inception. Since 2022, Jon has become more formally involved and worked with Katie to support the development of the C-Change project.
          </span>
        </p>
        <div class="more-button-holder" (click)="readMore(2)">
          <div class="more-button">
            <h3>{{ readMore2 ? 'Read Less' : 'Read More' }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="bio-element" [ngClass]="{'expanded': readMore3}">
      <div class="bio-element-image">
        <img src="../../../assets/images/dplatt.jpg">
      </div>
      <div class="bio-element-text">
        <h2>Dendy Platt</h2>
        <p class="bio-para">
          Dendy is an Honorary Senior Research fellow at the University of Bristol where he was formerly Head of the Children and Families Research Centre. Dendy worked as a social worker and team leader in Local Authority Children’s Services departments before moving in to university teaching...
          <span class="extra-content">
            Dendy was the academic lead on the research project which developed C-Change, building on research he had conducted to understand influences on parental engagement with Children’s Services.
          </span>
        </p>
        <div class="more-button-holder" (click)="readMore(3)">
          <div class="more-button">
            <h3>{{ readMore3 ? 'Read Less' : 'Read More' }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="bio-element" [ngClass]="{'expanded': readMore4}">
      <div class="bio-element-image" id="interface-element-image">
        <img src="../../../assets/images/interface-logo.jpg">
      </div>
      <div class="bio-element-text">
        <h2>Wendy Weal and Interface Enterprises</h2>
        <p class="bio-para">
          Interface is our licensed training partner. They are a national provider of specialist support, evaluation, training, information and resources for those working to transform the lives of vulnerable children, individuals, and families...
          <span class="extra-content">
            Their experienced and knowledgeable facilitators have delivered training in the C-Change approach since 2016, tailoring each C-Change programme to the needs of the client. C-Change training can be delivered face to face or facilitated online with a number of different options available. Costs vary according to the delivery option, the number of learners, and method of delivery. Please talk to Interface about how they can best support you at info@interfaceenterprises.co.uk or 01603 251730.
          </span>
        </p>
        <div class="more-button-holder" (click)="readMore(4)">
          <div class="more-button">
            <h3>{{ readMore4 ? 'Read Less' : 'Read More' }}</h3>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
