<section id="login-section" class="loginsignup-section">
  <div>
    <h1 class="section-title-type-a page-gutter-padding loginsignup-section-heading" id="login-section-heading">Resources Login</h1>
  </div>
    <div class="login-form">
      <mat-card class="form-box">
          <form (submit)="onLogin(loginForm)"  #loginForm="ngForm">
            <mat-form-field class="form-field">
              <input
                matInput
                type="email"
                name="email"
                ngModel
                required
                email
                minlength="3"
                placeholder="Please enter username."
                #emailInput="ngModel"
                >
              <mat-error *ngIf="emailInput.invalid"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
              matInput [type]="hide ? 'password' : 'text'"
              matInput
              rows="1"
              name="password"
              ngModel
              required
              placeholder="Please enter password."
              #passwordInput="ngModel">

              <mat-error *ngIf="passwordInput.invalid"></mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              color="accent"
              type="submit">Login</button>
          </form>
        </mat-card>
  </div>
</section>
