<section id="about-section" class="">
  <div class="about-circle-expanded" id="about-circle-expanded-a" [ngClass]="{'slide-in-animation': slideInAnim, 'slide-out-animation': slideOutAnim}" *ngIf="isShowDivIf">
    <button class="exit-button about-circle-expanded-exit button is-inverted is-secondary" (click)="onClick($event)"><strong>⇦</strong></button>
    <div class="about-circle-expanded-text page-gutter-padding">
      <h1>{{ aboutTitle }}</h1>
      <p>{{ aboutContent }}</p>
    </div>
  </div>
  <canvas id="line" class="canvas-box"></canvas>
  <div class="about-row is-row">
    <div class="about-row-col">
      <div id="about-circle-a" class="about-circle aboutCircleBounceUp" (click)="onClick('leftButton')">
        <h3 class="title">What is parental capacity to change?</h3>
      </div>
      <div id="about-circle-b" class="about-circle aboutCircleBounceDown" (click)="onClick('midButton')">
        <h3 class="content">Why is parental capacity for change important?</h3>
      </div>
      <div id="about-circle-c" class="about-circle aboutCircleBounceUp" (click)="onClick('rightButton')">
        <h3 class="title">Is C-Change evidence-informed?</h3>
      </div>
    </div>
  </div>
</section>
