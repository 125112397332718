<section class="audience-question-list-section">
    <div class="page-gutter-padding audience-question-list">
      <div class="">
        <h1>Here's are the feedback comments from our audience.</h1>

      <mat-accordion multi="true" *ngIf="audienceQuestions.length > 0">
              <mat-expansion-panel *ngFor="let audienceQuestion of audienceQuestions">
                <mat-expansion-panel-header>
                  {{ audienceQuestion.title }}
                </mat-expansion-panel-header>
                <p>{{ audienceQuestion.content }}</p>
              </mat-expansion-panel>
            </mat-accordion>
            <p class="info-text mat-body-1" *ngIf="audienceQuestions.length <= 0">No questions asked yet!</p>

  </div>
  </div>
  </section>
