import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AudienceQuestionService } from '../audienceQuestion.service';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AudienceQuestion } from '../audienceQuestion.model';
import { AudienceQuestionConfirmComponent } from '../dialogs/audienceQuestionConfirm.component';


@Component({
  selector: "app-audienceQuestion-create",
  templateUrl: "./audienceQuestion-create.component.html"
})

@Injectable()
export class AudienceQuestionCreateComponent {
  enteredTitle = "";
  enteredContent = "";

  constructor(public audienceQuestionService: AudienceQuestionService, private dialog: MatDialog) {

  }

  onAddAudienceQuestion(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.audienceQuestionService.addAudienceQuestion(form.value.title, form.value.content);
    this.dialog.open(AudienceQuestionConfirmComponent);
    form.resetForm();
  }
}
