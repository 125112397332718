<section id="signup-section" class="loginsignup-section">
  <div>
    <h1 class="section-title-type-a page-gutter-padding loginsignup-section-heading" id="signup-section-heading">Resources Signup</h1>
  </div>

  <div *ngIf="userIsAuthenticated && isAdmin">
    <div class="signup-form">
      <mat-card class="form-box">
          <form (submit)="onSignup(signupForm)"  #signupForm="ngForm">
            <mat-form-field class="form-field" color="white">
              <input
                matInput
                type="email"
                name="email"
                ngModel
                required
                email
                minlength="3"
                placeholder="Please enter username."
                #emailInput="ngModel"
                >
              <mat-error *ngIf="emailInput.invalid"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <textarea
                matInput
                rows="1"
                name="password"
                ngModel
                required
                placeholder="Please enter password."
                #passwordInput="ngModel"></textarea>
              <mat-error *ngIf="passwordInput.invalid"></mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              color="accent"
              type="submit">Signup</button>
          </form>
        </mat-card>
      </div>
    </div>

    <div *ngIf="!userIsAuthenticated && !isAdmin">
    <div class="restricted-sign-up-text">
        <p>Sign-up is currently restricted. Please contact <a href="mailto:katie.riches@bristol.ac.uk">katie.riches@bristol.ac.uk</a> to register your interest.</p>
    </div>
</div>


</section>
