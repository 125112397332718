import { NgForm } from "@angular/forms";
import { AuthService} from "../auth.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";


@Component({
  templateUrl: "./signup.component.html"
})

export class SignupComponent {
  userIsAuthenticated = false;
  isAdmin = false;
  private authListenerSubs: Subscription;
  private adminStatusSubs: Subscription;

  constructor (public authService: AuthService) {

  }

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.isAdmin = this.authService.getIsAdmin(); // Get initial admin status


    // Subscription for authentication changes
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });

    // Subscription for admin status changes
    this.adminStatusSubs = this.authService
      .getAdminStatusListener()
      .subscribe(isAdmin => {
        this.isAdmin = isAdmin;
        console.log('Updated Admin Status:', this.isAdmin); // Debug: Check updated status
      });
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.adminStatusSubs.unsubscribe(); // Make sure to unsubscribe from the admin listener
  }

  onSignup(form: NgForm){
    if(form.invalid){
      return;
    }
    this.authService.createUser(form.value.email, form.value.password);
  }

}
