import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent} from './pages/homepage/home/home.component';
import { ResourcesMenuComponent } from './pages/resourcesmenu/resourcesmenu.component';
import { AudienceContentReviewComponent } from './pages/audienceContentReview/audienceContentReview.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { GlobalHeaderComponent } from './persistentComponents/globalHeader/globalHeader.component';
import { GlobalFooterComponent } from './persistentComponents/globalFooter/globalFooter.component';
import { GlobalFaqComponent } from './persistentComponents/globalFaq/globalFaq.component';
import { HomeFaqComponent } from './pages/homepage/homefaq/homefaq.component';
import { ResourcesFaqComponent } from './pages/resourcesmenu/resourcesfaq/resourcesfaq.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OverviewComponent } from './pages/homepage/overview/overview.component';
import { AboutComponent } from './pages/homepage/about/about.component';
import { HiwComponent } from './pages/homepage/hiw/hiw.component';
import { TrainingComponent } from './pages/homepage/training/training.component';
import { TeamComponent } from './pages/homepage/team/team.component';
import { HomepageVideo1Component } from './pages/homepage/homepage-video-1/homepage-video-1.component';
import { HomepageVideo2Component } from './pages/homepage/homepage-video-2/homepage-video-2.component';
import { TestimonialComponent } from './pages/homepage/testimonial/testimonial.component';
import { ResourcesHomeComponent } from './pages/homepage/resourceshome/resourceshome.component';
import { AudienceQuestionCreateComponent } from './pages/audienceQuestion/audienceQuestionCreate/audienceQuestion-create.component';
import { AudienceQuestionListComponent } from './pages/audienceQuestion/audienceQuestionList/audienceQuestion-list.component';
import { AudienceQuestionConfirmComponent } from './pages/audienceQuestion/dialogs/audienceQuestionConfirm.component';
import { AudienceContentCreateComponent } from './pages/audienceContent/audienceContentCreate/audienceContent-create.component';
import { AudienceContentListComponent } from './pages/audienceContent/audienceContentList/audienceContent-list.component';
import { AudienceContentConfirmComponent } from './pages/audienceContent/dialogs/audienceContentConfirm.component';

import { ErrorDialogComponent } from './auth/error-dialog/error-dialog.component'; // Update path as necessary

import { AuthInterceptor } from './auth/auth-interceptor';

@NgModule({
  declarations: [
  AppComponent,
	HomeComponent,
  ResourcesMenuComponent,
  AudienceContentReviewComponent,
	PrivacyComponent,
  GlobalHeaderComponent,
	GlobalFooterComponent,
  GlobalFaqComponent,
  LoginComponent,
  SignupComponent,
  AudienceQuestionCreateComponent,
  AudienceQuestionListComponent,
  AudienceQuestionConfirmComponent,
  AudienceContentCreateComponent,
  AudienceContentListComponent,
  AudienceContentConfirmComponent,
  OverviewComponent,
  AboutComponent,
  TrainingComponent,
  TeamComponent,
  HiwComponent,
  HomepageVideo1Component,
  HomepageVideo2Component,
  TestimonialComponent,
  ResourcesHomeComponent,
  HomeFaqComponent,
  ResourcesFaqComponent,
  ErrorDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
