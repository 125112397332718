<div class="footer-section">
	<div class="footer-content">

	  <div class="is-col footer-left">
	    <h6>&copy; 2024. C-Change. All rights reserved.</h6>
	  </div>

	  <div class="is-col footer-right">
				<dl>
	        <dd></dd>
					<dd></dd>
					<dd></dd>
					<dd></dd>
	        <dd></dd>
	        </dl>
		      </div>
	      </div>

</div>
