 import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: "app-overview",
  templateUrl: './overview.component.html'
})
export class OverviewComponent implements OnInit {


  constructor(private renderer:Renderer2) { }

  ngOnInit(): void {

  }

}
