import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';

import { AudienceContent } from "../audienceContent.model";
import { AudienceContentService } from '../audienceContent.service';


@Component({
  selector: "app-audienceContent-list",
  templateUrl: "./audienceContent-list.component.html"
})
export class AudienceContentListComponent implements OnInit, OnDestroy {
  // posts = [
  //   { title: "First Post", content: "This is the first post's content" },
  //   { title: "Second Post", content: "This is the second post's content" },
  //   { title: "Third Post", content: "This is the third post's content" }
  // ];

  audienceContents: AudienceContent[] = [];
  private audienceContentsSub: Subscription;

  posts = [
    {title: "Hello eh, I am Content #QQ1", content: "Thanks, I am answer #1"},
    {title: "Hello eh, I am Content #QQ2", content: "Thanks, I am answer #2"},
    {title: "Hello eh, I am Content #QQ3", content: "Thanks, I am answer #3"},
  ]

  constructor(public audienceContentService: AudienceContentService) {}

  ngOnInit() {
//    this.faqs = this.faqService.getFaqs();
    this.audienceContentService.getAudienceContents();
    this.audienceContentsSub = this.audienceContentService.getAudienceContentUpdateListener()
    .subscribe((audienceContents: AudienceContent[]) => {
      this.audienceContents = audienceContents;
    });
  }

  ngOnDestroy() {
    this.audienceContentsSub.unsubscribe();
  }


}
