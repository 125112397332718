<header>
	<app-globalHeader></app-globalHeader>
</header>

<main class="is-container is-container-column">
	<router-outlet></router-outlet>
</main>

<footer>
 	<app-globalfooter></app-globalfooter>
</footer>
