<section class="is-fullscreen" id="fullscreenMid">

    <div class="mid-text-container" id="mid-text-container-1">
      <div class="page-gutter-padding">
        <h1 class="section-title-type-b" id="mid-title">How Can C-Change Help?</h1>
        <p class="hello-lead lead-text">Please scroll down to watch a 4 minute film from Katie Riches highlighting how C-Change can help in social work practice, when it can be used and the principles of the approach.</p>
      </div>
    </div>

    <div id="fullscreenMidContent">
        <video #fullscreenMidVideo playsinline controls id="heroVideo1" class="midVideo" poster="../../../assets/images/midFrame.jpg">
            <source src="https://stuartiaingray.com/wp-content/uploads/2024/01/cchange1.mp4" type="video/mp4">
        </video>
        <div #playButton class="play-button"></div>

      <div class="mid-text-container" id="mid-text-container-2">
        <div class="page-gutter-padding">
          <p class="hello-lead lead-text"></p>
          <p class="hello-follow follow-text"></p>
        </div>
      </div>
  </div>
</section>
