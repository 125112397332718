<section class="is-fullscreen" id="faq-section">
  <div class="homeFaq-top-divider"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"><path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path></svg></div>
  <h1 class="section-title-type-b  page-gutter-padding fade-in" id="faq-title">Frequently Asked Questions</h1>
  <div id="faq-accordion">
    <mat-accordion multi="true">

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>Is C-Change training accredited?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">C-Change is not offered as part of a programme that has a qualification attached.  Attendance can obviously be used as evidence of CPD for continued social work registration and certificates are provided.</p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>How experiential is the training?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
                <p class = "faq-text">Throughout the training the emphasis is on application of C-Change to practice and trying out.  We begin with realistic case studies to outline the principles of C-Change and end by applying the model to live cases that the practitioners are working with.</p>
            </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>How can C-Change work with the Graded Care Profile or Signs of Safety?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">C-Change is designed as a complementary assessment which focuses on parental capacity for change.  It does not replace a needs assessment.</p>
              <p class = "faq-text">The Graded Care Profile could be used to inform the C-Change assessment through assisting in measurement of observable change, identifying the target parental behaviours that need to change, and gaining some insights into barriers or facilitators.</p>
              <p class = "faq-text">Many of the Local Authorities who have used C-Change also use Signs of Safety. The two approaches have different foci but work well together.</p>
            </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="faq-accordion-panel">
              <mat-expansion-panel-header class="faq-accordion-panel-header">
                <h3>Can C-Change be used by practitioners other than social workers e.g. health visitors or children centre workers?</h3>
              </mat-expansion-panel-header>
              <div class = faq-content>
              <p class = "faq-text">Yes! There has been interest in C-Change from health visitors and Early Help teams as well as children’s teams within statutory services.  The principles that underpin C-Change are taken from behaviour change theory and thus can be applied to any context where change is desired and supported.</p>
              </div>
            </mat-expansion-panel>

          <mat-expansion-panel class="faq-accordion-panel">
            <mat-expansion-panel-header class="faq-accordion-panel-header">
              <h3>Is there an evidence base for the effectiveness of the approach?</h3>
            </mat-expansion-panel-header>
            <div class = faq-content>
            <p class = "faq-text">C-Change is based on behaviour change theory, specifically on a framework that combines the principles from 83 behaviour change theories – the TDF or COM-B.  There is a solid and continuously growing research base that sits behind these frameworks and by basing C-Change on this framework, the approach itself is grounded in evidence.</p>
            <p class = "faq-text">The Pilot evaluation of C-Change conducted in 2015 had a sample size of approximately 100 social workers and team managers.  All participants were asked to complete pre and post questionnaires and 12 practitioners participated in interviews.  Social workers reported that the C-Change approach was a necessary element of good practice and easy to use.  The majority of practitioners had been able to apply it to their practice within 3 months of attending the training.  A self-report questionnaire found a positive influence on social worker decision-making within children’s timescales.</p>
            <p class = "faq-text">If you would like to read more information about the evidence base and evaluation please click here.</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
  </div>
</section>
