<div id="globalHeader">
  <div class="globalNavImgDivHolder">
    <div class="globalNavImgDiv"></div>
  </div>

  <div class="mobile-menu">
    <mat-menu #appMenu="matMenu">
      <button mat-menu-item>
        <a class="mobile-menu-item" routerLink="/" fragment="hero-section">Home</a>
      </button>
      <button mat-menu-item>
        <a class="mobile-menu-item" routerLink="/hiw">How it Works</a>
      </button>
      <button mat-menu-item>
        <a class="mobile-menu-item" routerLink="/team">Team</a>
      </button>
      <button mat-menu-item>
        <a class="mobile-menu-item" routerLink="/homefaq">FAQ</a>
      </button>
      <button mat-menu-item *ngIf="!userIsAuthenticated">
        <a class="mobile-menu-item" routerLink="/login">Login</a>
      </button>
      <button mat-menu-item *ngIf="!userIsAuthenticated">
        <a class="mobile-menu-item" routerLink="/signup">Signup</a>
      </button>
      <button mat-menu-item *ngIf="userIsAuthenticated">
        <a class="mobile-menu-item" routerLink="/resourcesmenu">Resources</a>
      </button>
      <button mat-menu-item *ngIf="userIsAuthenticated">
        <button class="mobile-menu-item" (click)="onLogout()">Logout</button>
      </button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <a id="mobile-menu-title">Menu</a>
    </button>
  </div>

  <div id="globalMenu" class="is-hidden-mobile">
    <nav>
      <ul>
        <li>
          <a routerLink="/" fragment="hero-section">Home</a>
        </li>
        <li>
          <a routerLink="/hiw">How it<br>Works</a>
        </li>
        <li>
          <a routerLink="/team">Team</a>
        </li>
        <li>
          <a routerLink="/homefaq">FAQ</a>
        </li>
        <li *ngIf="!userIsAuthenticated">
          <a routerLink="/login">Login</a>
        </li>
        <li *ngIf="!userIsAuthenticated">
          <a routerLink="/signup">Signup</a>
        </li>
        <li *ngIf="userIsAuthenticated">
          <a routerLink="/resourcesmenu">Resources</a>
        </li>
        <li *ngIf="userIsAuthenticated">
          <button class="button is-inverted is-secondary" (click)="onLogout()">Logout</button>
        </li>
      </ul>
    </nav>
  </div>
</div>
