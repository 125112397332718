 import { Component, OnInit, Renderer2 } from '@angular/core';

 declare var $: any;
 declare var mainBallSetup: any;
 declare var line: any;
 declare var rock: any;
 declare var fadein: any;
 declare var randomMove: any;

@Component({
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  isShowDivIf = false;
  slideInAnim = false;
  slideOutAnim = false;

  constructor(private renderer:Renderer2) { }

  ngOnInit(): void {


    $(document).ready(() => {
      console.log('Hey');
    });


  new line();
  new rock();
  new fadein();
  new randomMove();
  //new mainBallSetup();

}

onClick(event: Event) {

  if(!this.isShowDivIf) {
    console.log("okay hi");
    this.slideInAnim = true;
    this.isShowDivIf = true;
  }


  else if(this.isShowDivIf) {
    this.slideInAnim = false;
    this.slideOutAnim = true;
    setTimeout (() => {
           console.log("okay bye");
           this.isShowDivIf = false;
           this.slideOutAnim = false;
        }, 1000);
  }


}

title = 'app-js';

}
