<section class="" id="testimonials-section">
  <h1 class="section-title-type-a page-gutter-padding section-title-type-b" id="testimonials-title">Testimonials</h1>

  <div id="testimonials-grid-box" class="testimonials-grid">
    <div id="testimonials-element-a" class="testimonials-element">
       <div id="testimonials-element-image-a" class="testimonials-element-image">
        <img src="../../../assets/images/quotes2.png">
       </div>
       <div id="testimonials-element-text-a" class="testimonials-element-text">
         <p><i>“... although I’ve been practising for a long time, until I did a C-Change assessment, you didn’t actually sit down and actually think about it.  ... The changes we are asking parents to make, for them, are so life changing… when I’m asking them to stop using a substance which is their life, that is what they do, you know… you’re not just asking them to change, you’re asking them to change so much: their social circles …, the places where they go, the way they lead their lives.” </i></p>
         <p><strong>- Social Worker, Safeguarding Team</strong></p>
       </div>
    </div>

    <div id="testimonials-element-b" class="testimonials-element">
      <div id="testimonials-element-image-b" class="testimonials-element-image">
        <img src="../../../assets/images/quotes2.png">
      </div>
      <div id="testimonials-element-text-b" class="testimonials-element-text">
        <p><i>“You might be thinking along the lines of you know this parent can’t do it because there’s this thing that’s really getting in the way of… this barrier that’s really hindering her or him, because they can’t see past that.  And you, kind of, know that’s a problem, but then C-Change helps you to think about why it’s a problem, and what you might be able to do about it, and how big a problem is it”</i></p>
        <p><strong>- Social worker, Safeguarding Team</strong></p>
      </div>
    </div>

   <div id="testimonials-element-c" class="testimonials-element">
       <div id="testimonials-element-image-c" class="testimonials-element-image">
         <img src="../../../assets/images/quotes2.png">
       </div>
       <div id="testimonials-element-text-c" class="testimonials-element-text">
       <p><i>“I never ever had broken it down like that before… Never to break it down to being five domains like Habits, Automatic Responses, you know, all of those Contextual factors.  I didn’t have any framework to hang that on.  So it’s helped massively”.</i></p>
       <p><strong>- Social worker, locality team</strong></p>
     </div>
   </div>

   <div id="testimonials-element-d" class="testimonials-element">
       <div id="testimonials-element-image-d" class="testimonials-element-image">
         <img src="../../../assets/images/quotes2.png">
       </div>
       <div id="testimonials-element-text-d" class="testimonials-element-text">
       <p><i>“C-Change offered a really good framework to phrase, if you like, and cement where those concerns (about capacity to change) sat in the assessment.  I think, without that sort of template, the social worker could likely find themselves stating things like “my observations led me to believe da, da, da..” which of course when you’re actually in court is sometimes upheld and other times not.”
       </i></p>
       <p><strong>- Team manager, locality team</strong></p>
     </div>
   </div>
 </div>
</section>
