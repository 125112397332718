import { AudienceQuestion } from './audienceQuestion.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class AudienceQuestionService {
  private audienceQuestions: AudienceQuestion[] = [];
  private audienceQuestionsUpdated = new Subject<AudienceQuestion[]>();

constructor(private http: HttpClient) {}

  getAudienceQuestions() {
//    this.http.get<{message: string, audienceQuestions: any}>('http://localhost:3000/api/audienceQuestion')
    this.http.get<{message: string, audienceQuestions: any}>('https://cchange.herokuapp.com/api/audienceQuestion')
//*use this on local     this.http.get<{message: string, audienceQuestions: any}>('http://localhost:3000/api/audienceQuestion')
    .pipe(map((audienceQuestionData) => {
      return audienceQuestionData.audienceQuestions.map((audienceQuestion: any) => {
        return {
          title: audienceQuestion.title,
          content: audienceQuestion.content,
          id: audienceQuestion._id
        };
      });
    })) //the result of the pipe is the transformedfaqData
    .subscribe ((transformedAudienceQuestionData) => {
      this.audienceQuestions = transformedAudienceQuestionData;
      this.audienceQuestionsUpdated.next([...this.audienceQuestions]);
    });
  //  return [...this.faqs];
  }

  getAudienceQuestionUpdateListener() {
    return this.audienceQuestionsUpdated.asObservable();
  }

  addAudienceQuestion(title: string, content: string) {
    const audienceQuestion: AudienceQuestion = {id: 'null', title: title, content: content};
//    this.http.post<{message: string, audienceQuestions: AudienceQuestion[]}>('http://localhost:3000/api/insertAudienceQuestionList', audienceQuestion)
    this.http.post<{message: string, audienceQuestions: AudienceQuestion[]}>('https://cchange.herokuapp.com/api/audienceQuestion', audienceQuestion)
//*use this on local   this.http.post<{message: string, audienceQuestions: AudienceQuestion[]}>('http://localhost:3000/api/audienceQuestion', audienceQuestion)
    .subscribe ((responseData) => {
        console.log(responseData.message);
        this.audienceQuestions.push(audienceQuestion);
        this.audienceQuestionsUpdated.next([...this.audienceQuestions]);
      });
  }
}
